import { ModalSweetAlert } from "@components/general/Alertas/Alertas";
import TitleDashboard from "@components/general/Titles/TitleDashboard";
import { faChalkboardTeacher, faDownload, faExpand, faPlay, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { downloadArchivoLeccion } from "@helpers/httpRequests/httpRequestsForoEmpresarial/httpRequestsForoEmpresarial";
import { getLeccionesByidGrupo } from "@helpers/httpRequests/httpRequestsLeccionesPersonalizadas/httpRequestsLeccionesPersonalizadas";
import React, { useEffect, useRef, useState } from "react";
import { Accordion, Button, Card, Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import NuevaSolicitud from "../Modales/lecciones/NuevaSolicitud";
// import { Accordion, AccordionTab } from "primereact/accordion";

const Lecciones = ({ isTabActive, tab, rol, idGrupo, asesor }) => {
	const [leccionesEmpresariales, setLeccionesEmpresariales] = useState([]);

	const [infoDescarga, setInfoDescarga] = useState({});

	const [datosLeccion, setDatosLeccion] = useState({
		url: "",
		nombreLeccion: "",
		nombreArhivo: "",
	});

	const iframeRef = useRef();

	const [isFullscreen, setIsFullscreen] = useState(false);

	const [showLeccion, setShowLeccion] = useState(false);

	function requestFullscreen() {
		iframeRef.current.requestFullscreen();
	}

	function handleFullscreenChange() {
		setIsFullscreen(document.fullscreenElement === iframeRef.current);
	}

	//*MODALES

	const handleNuevaSolicitud = () => {
		// console.log('solicitando nueva lección: ' );

		// console.log("asesor: ", asesor);

		// console.log("idGrupo: ", idGrupo);

		ModalSweetAlert({
			title: "Solicitar Lección",
			html: <NuevaSolicitud asesor={asesor} idGrupo={idGrupo} />,
			showConfirmButton: false,
			showCloseButton: true,
			width: "700px",
		});
	};

	//*FUNCIONES DEL COMPONENTE

	const handleVerLeccion = (objetoLeccion) => {
		const { nombreLeccion, nombreArchivo, grupo, nombrePlantilla, tipoArchivoPlantilla } = objetoLeccion;
		// console.log(grupo);

		const url = window.location.origin + `/leccionesPersonalizadas/grupo_${grupo}/${nombreArchivo}/index.html`;

		setDatosLeccion({
			nombreArchivo,
			nombreLeccion,
			nombrePlantilla,
			tipoArchivoPlantilla,
			url,
		});

		setShowLeccion(true);
	};

	const descargarPlantilla = async () => {
		try {
			//Se recupera el buffer del archivo
			const { data } = await downloadArchivoLeccion(infoDescarga);

			const { buffer, tipoArchivoPlantilla, nombreArchivo } = data;

			const dataBinaria = new Uint8Array(buffer.data);

			const datos = new Blob([dataBinaria], { type: tipoArchivoPlantilla });

			var link = document.createElement("a");

			link.href = window.URL.createObjectURL(datos);

			var fileName = nombreArchivo;

			link.download = fileName;

			link.click();
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		const obtenerLeccionesEmpresariales = async () => {
			try {
				const { data } = await getLeccionesByidGrupo(idGrupo);
				if (Array.isArray(data)) {
					setLeccionesEmpresariales(data);
				} else {
					setLeccionesEmpresariales([data]);
				}
			} catch (error) {
				// console.log(error);
				setLeccionesEmpresariales([]);
			}
		};

		obtenerLeccionesEmpresariales();

		document.addEventListener("fullscreenchange", handleFullscreenChange);

		return () => {
			document.removeEventListener("fullscreenchange", handleFullscreenChange);
		};
	}, []);

	useEffect(() => {
		if (tab === "lecciones") {
			setShowLeccion(false);

			const obtenerLeccionesEmpresariales = async () => {
				try {
					const { data } = await getLeccionesByidGrupo(idGrupo);
					
					if (Array.isArray(data)) {
						setLeccionesEmpresariales(data);
					} else {
						setLeccionesEmpresariales([data]);
					}
				} catch (error) {
					console.log(error);
					setLeccionesEmpresariales([]);
				}
			};

			obtenerLeccionesEmpresariales();
		}
	}, [tab]);

	return (
		isTabActive && (
			<Container>
				<Row className="mt-5">
					<Col>
						<Row className="justify-content-between mb-4">
							{rol === "asesor" && (
								<Col align={"start"} className="mb-3 p-0 col-auto">
									<Button className="btn-sm" onClick={handleNuevaSolicitud} variant="success">
										<FontAwesomeIcon icon={faPlus} /> Solicitar lección
									</Button>
								</Col>
							)}

							{showLeccion && datosLeccion.nombrePlantilla !== null && (
								<Col align={"start"} className="mb-3 p-0 col-auto">
									<Button onClick={descargarPlantilla}>
										<FontAwesomeIcon icon={faDownload} /> Descargar plantilla
									</Button>
								</Col>
							)}
						</Row>
						{showLeccion && (
							<Row className="mb-3">
								<Col className="text-capitalize">
									<TitleDashboard title={datosLeccion.nombreLeccion} />
								</Col>
							</Row>
						)}
					</Col>
				</Row>
				{showLeccion && (
					<Row>
						<Col align={"end"} className="mb-3 overlay">
							<OverlayTrigger className="overlay-trigger" placement={"top"} overlay={<Tooltip id={`tooltip-bottom}`}>Pantalla completa.</Tooltip>}>
								<Button className="btn-fullscreen" onClick={requestFullscreen} variant="secondary">
									<FontAwesomeIcon icon={faExpand} />
								</Button>
							</OverlayTrigger>
						</Col>
					</Row>
				)}
				<Row className="">
					{showLeccion ? (
						<Col className="text-center embed-responsive embed-responsive-16by9">
							<iframe ref={iframeRef} style={{ border: "none" }} src={datosLeccion.url} title={datosLeccion.nombreLeccion}></iframe>
						</Col>
					) : (
						<Col className="text-center mt-5" style={{ color: "grey" }}>
							<FontAwesomeIcon icon={faChalkboardTeacher} className="mb-3 icon" size="4x" />
							<h4>No hay ninguna lección seleccionada</h4>
						</Col>
					)}
				</Row>

				<Row className="justify-content-center mb-5 mt-5">
					<Col className="col-12">

						<Accordion defaultActiveKey="0">
							<Accordion.Item eventKey="0">
								<Accordion.Header>
									Lecciones de apoyo ({leccionesEmpresariales.length} {leccionesEmpresariales.length > 1 ? "Disponibles" : leccionesEmpresariales.length === 0 ? "Disponibles" : "Disponible"})
								</Accordion.Header>

								<Accordion.Body>
									<div className="">
										{leccionesEmpresariales.length > 0 ? (
											leccionesEmpresariales.map((leccionEmpresarial, key) => (
												<Row className=" justify-content-center mb-3">
													<Col
														className="col-10 d-flex"
														style={{
															backgroundColor: "whitesmoke",
															alignItems: "center",
															borderRadius: ".5rem",
														}}>
														<h5>
															{key + 1}- {leccionEmpresarial.nombre}
														</h5>
													</Col>
													<Col className="col-auto">
														<Button
															onClick={() => {
																handleVerLeccion({
																	nombreLeccion: leccionEmpresarial.nombre,
																	grupo: leccionEmpresarial.idGrupo,
																	nombreArchivo: leccionEmpresarial.archivoLeccion,
																	nombrePlantilla: leccionEmpresarial.nombrePlantilla,
																	tipoArchivoPlantilla: leccionEmpresarial.tipoArchivoPlantilla,
																});

																setInfoDescarga({
																	nombreLeccion: leccionEmpresarial.nombre,
																	grupo: leccionEmpresarial.idGrupo,
																	nombreArchivo: leccionEmpresarial.archivoLeccion,
																	nombrePlantilla: leccionEmpresarial.nombrePlantilla,
																	tipoArchivoPlantilla: leccionEmpresarial.tipoArchivoPlantilla,
																});
															}}>
															<FontAwesomeIcon className="icon" icon={faPlay} /> Reproducir
														</Button>
													</Col>
												</Row>
											))
										) : (
											<h4>No hay lecciones disponibles</h4>
										)}
									</div>
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
					</Col>
				</Row>
			</Container>
		)
	);
};

export default Lecciones;
