import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";
import endpointsPaths from "../endpointsPaths/endpointsPaths";
import httpRequest from "../httpRequest";

export const getPriceAbono = (montoLibre = false, esCompraExterna) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: priceAbono } = await httpRequest({
				url: `${endpointsPaths.pagos}/${httpRequestsPaths.pagos.getPriceAbono}`,
				method: "POST",
				body: JSON.stringify({montoLibre, esCompraExterna})
			});

			resolve(priceAbono);
		} catch (error) {
			// console.log("Error al obtener el precio de pago: ", error);

			reject(error);
		}
	});
};

export const getPriceLiquidacion = (esCompraExterna) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: priceLiquidacion } = await httpRequest({
				url: `${endpointsPaths.pagos}/${httpRequestsPaths.pagos.getPriceLiquidacion}`,
				method: "POST",
				body: JSON.stringify({esCompraExterna})
			});

			resolve(priceLiquidacion);
		} catch (error) {
			// console.log("Error al obtener el precio de la liquidacion: ", error);
			reject(error);
		}
	});
};

export const getPriceCertificacionMosAdicional = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: priceCertificacionMosAdicional } = await httpRequest({
				url: `${endpointsPaths.pagos}/${httpRequestsPaths.pagos.getPriceCertificacionMosAdicional}`,
				method: "POST",
			});

			resolve(priceCertificacionMosAdicional);
		} catch (error) {
			// console.log("Error al obtener el precio de la liquidacion: ", error);
			reject(error);
		}
	});
};

export const getPriceCertificacionMosAdicionalExterna = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: priceCertificacionMosAdicional } = await httpRequest({
				url: `${endpointsPaths.pagos}/${httpRequestsPaths.pagos.getPriceCertificacionMosAdicionalExterna}`,
				method: "POST",
			});

			resolve(priceCertificacionMosAdicional);
		} catch (error) {
			// console.log("Error al obtener el precio de la liquidacion: ", error);
			reject(error);
		}
	});
};

export const getPriceLicenciaLecturaVeloz = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data } = await httpRequest({
				url: `${ endpointsPaths.pagos }/${ httpRequestsPaths.pagos.getPrecioLecturaVeloz }`,
				method: 'POST',
			});

			resolve(data);
		} catch (error) {
			reject(error);	
		}
	});
};

export const createCheckoutSession = (sessionData) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: checkoutSession } = await httpRequest({
				url: `${endpointsPaths.pagos}/${httpRequestsPaths.pagos.createCheckoutSession}`,
				method: "POST",
				body: JSON.stringify(sessionData),
			});

			resolve(checkoutSession);
		} catch (error) {
			// console.log("Error al crear la sesión de checkout: ", error);

			reject(error);
		}
	});
};

export const retrievePaymentIntent = (paymentIntentId) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: paymentIntent } = await httpRequest({
				url: `${endpointsPaths.pagos}/${httpRequestsPaths.pagos.retrievePaymentIntent}/${paymentIntentId}`,
				method: "POST",
			});

			resolve(paymentIntent);
		} catch (error) {
			// console.log("Error al obtener el payment intent: ", error);

			reject(error);
		}
	});
};

export const enviarCorreoFacturacion = (datosFacturacion) => {
	return new Promise(async (resolve, reject) => {
		try {
			const facturacionSolicitada = await httpRequest({
				url: `${endpointsPaths.mailers}/${httpRequestsPaths.mailers.enviarCorreoFacturacion}`,
				method: "POST",
				body: JSON.stringify(datosFacturacion),
			});

			resolve(facturacionSolicitada);
		} catch (error) {
			// console.log("Error al enviar el correo de facturacion: ", error);

			reject(error);
		}
	});
};

export const validateCodigoDescuento = (codigoDescuento) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data } = await httpRequest({
				url: `${endpointsPaths.pagos}/${httpRequestsPaths.pagos.compra.validateCodigoDescuento}`,
				method: "POST",
				body: JSON.stringify({ codigoDescuento }),
			});

			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};

export const getPricesCompra = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data } = await httpRequest({
				url: `${ endpointsPaths.pagos }/${ httpRequestsPaths.pagos.compra.getPricesCompra }`,
				method: 'POST'
			});

			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};

export const getPricesCompraExterna = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data } = await httpRequest({
				url: `${ endpointsPaths.pagos }/${ httpRequestsPaths.pagos.compra.getPricesCompraExterna }`,
				method: 'POST'
			});

			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};

export const getPricesRenovacion = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data } = await httpRequest({
				url: `${ endpointsPaths.pagos }/${ httpRequestsPaths.pagos.getPricesRenovacion }`,
				method: 'POST'
			});

			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};

export const getFolioContratoCompra = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: folioContrato } = await httpRequest({
				url: `${ endpointsPaths.pagos }/${ httpRequestsPaths.pagos.compra.getFolioContratoCompra }`,
				method: 'POST'
			});			

			resolve(folioContrato);
		} catch (error) {
			reject(error);
		}
	});
};

export const getPreciosCompraLinkUnico = (msi) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: precio } = await httpRequest({
				url: `${ endpointsPaths.pagos }/${ httpRequestsPaths.pagos.compra.getPreciosCompraLinkUnico }${ msi ? '/true' : '' }`,
				method: 'POST'
			});

			resolve(precio);
		} catch (error) {
			reject(error);
		}
	});
};

export const retrieveCheckoutSessionByMetadata = (folioContrato, id) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data } = await httpRequest({
				url: `${ endpointsPaths.pagos }/${ httpRequestsPaths.pagos.retrieveCheckoutSessionByMetadata }/${ folioContrato }`,
				method: 'POST',
				body: JSON.stringify({id})
			});
			
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};