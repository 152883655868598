import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";
import endpointsPaths from "../endpointsPaths/endpointsPaths";
import httpRequest from "../httpRequest";

export const addAgendaAsesoria = (datosAgenda) => {
    return new Promise(async (resolve, reject) => {
        try {
            await httpRequest({
                url: `${ endpointsPaths.agendaAsesorias }/${ httpRequestsPaths.agendaAsesorias.addAgendaAsesoria }`,
                method: 'POST',
                body: JSON.stringify(datosAgenda)
            });

            resolve();
        } catch (error) {
            reject(error);
        }
    });
};

export const updateAgendaAsesoriaFeedback = (datosFeedback) => {
    return new Promise(async (resolve, reject) => {
        try {
            await httpRequest({
                url: `${ endpointsPaths.agendaAsesorias }/${ httpRequestsPaths.agendaAsesorias.updateAgendaAsesoriaFeedback }`,
                method: 'POST',
                body: JSON.stringify(datosFeedback)
            });

            resolve();
        } catch (error) {
            reject(error);
        }
    });
};

export const updateAgendaAsesoria = (datosAsesoria) => {
    return new Promise(async (resolve, reject) => {
        try {
            await httpRequest({
                url: `${ endpointsPaths.agendaAsesorias }/${ httpRequestsPaths.agendaAsesorias.updateAgendaAsesoria }`,
                method: 'POST',
                body: JSON.stringify(datosAsesoria)
            });

            resolve();
        } catch (error) {
            reject(error);
        }
    });
};

export const updateAgendaAsesoriaResuelta = (idAgendaAsesoria) => {
    return new Promise(async (resolve, reject) => {
        try {
            await httpRequest({
                url: `${ endpointsPaths.agendaAsesorias }/${ httpRequestsPaths.agendaAsesorias.updateAgendaAsesoriaResuelta }/${idAgendaAsesoria}`,
                method: 'POST',
            });

            resolve();
        } catch (error) {
            reject(error);
        }
    });
};

export const updatePrivacidadAgendaAsesoria = (datosAsesoria) => {
    return new Promise(async (resolve, reject) => {
        try {
            await httpRequest({
                url: `${ endpointsPaths.agendaAsesorias }/${ httpRequestsPaths.agendaAsesorias.updatePrivacidadAgendaAsesoria }`,
                method: 'POST',
                body: JSON.stringify(datosAsesoria)
            });

            resolve();
        } catch (error) {
            reject(error);
        }
    });
};

export const getHistorialAsesorias = async (idPerfil) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { data: historial } = await httpRequest({
                url: `${endpointsPaths.agendaAsesorias}/${ httpRequestsPaths.agendaAsesorias.getHistorialAsesorias }/${ idPerfil }`,
                method: 'POST',
            });

            if (!Array.isArray(historial)) {
                historial = [historial];
            }

            resolve(historial);
        } catch (error) {
            reject(error);
        }
    });
};

export const getUltimaAgenda = async (idPerfil) => {
    return new Promise(async (resolve, reject) => {
        try {
            const { data: ultimaAgenda } = await httpRequest({
                url: `${endpointsPaths.agendaAsesorias}/${ httpRequestsPaths.agendaAsesorias.getUltimaAgenda }/${ idPerfil }`,
                method: 'POST',
            });

            resolve(ultimaAgenda);
        } catch (error) {
            reject(error);
        }
    });
};

export const getAgendasAsesorias = () => {
    return new Promise(async (resolve, reject) => {
        try {
            let { data: agendas } = await httpRequest({
                url: `${ endpointsPaths.agendaAsesorias }/${ httpRequestsPaths.agendaAsesorias.getAgendasAsesorias }`,
                method: 'POST'
            });

            if (!Array.isArray(agendas)) {
                agendas = [agendas];
            }

            resolve(agendas);
        } catch (error) {
            reject(error);
        }
    });
};

export const getAgendasAsesoriasPublicas = () => {
    return new Promise(async (resolve, reject) => {
        try {
            let { data: agendas } = await httpRequest({
                url: `${ endpointsPaths.agendaAsesorias }/${ httpRequestsPaths.agendaAsesorias.getAgendasAsesoriasPublicas }`,
                method: 'POST'
            });

            if (!Array.isArray(agendas)) {
                agendas = [agendas];
            }

            resolve(agendas);
        } catch (error) {
            reject(error);
        }
    });
};

export const getDisponibilidadHorario = (datosValidacion) => {
    return new Promise(async (resolve, reject) => {
        try {
            await httpRequest({
                url: `${ endpointsPaths.agendaAsesorias }/${ httpRequestsPaths.agendaAsesorias.getDisponibilidadHorario }`,
                method: 'POST',
                body: JSON.stringify(datosValidacion)
            });

            console.log('disponible');

            resolve(true);
        } catch (error) {
            reject(error);
        }
    });
};

export const cancelAgendaAsesoria = (idAgendaAsesoria, datosAsesoria) => {
    return new Promise(async (resolve, reject) => {
        try {
            await httpRequest({
                url: `${ endpointsPaths.agendaAsesorias }/${ httpRequestsPaths.agendaAsesorias.cancelAgendaAsesoria }/${ idAgendaAsesoria }`,
                method: 'POST',
                body: JSON.stringify(datosAsesoria),
            });

            resolve();
        } catch (error) {
            reject(error);
        }
    });
};