import React, { useState } from 'react'
import { Button, Col, Container, Image, Row } from 'react-bootstrap'
import { Notificacion, NotificacionExtendida } from '@components/general/Alertas/Alertas';
import getUnixTimestamp from '@helpers/dates/getUnixTimestamp/getUnixTimestamp';
import { addParticipante } from '@helpers/httpRequests/httpRequestMisiones/httpRequestMisiones';
import { Alert } from '@mui/material';

const ParticiparMision = ({infoPerfil, infoMision, setInscrito}) => {
    const {nombreCompleto, apellidos, idPerfil, correo, avatarPerfil} = infoPerfil;

    const {titulo, descripcion, iconoProyecto, idMision} = infoMision;

    const [loading, setLoading] = useState(false);


    const handleNuevoParticipante = async() =>{
        try {
            setLoading(true);
            const code = await addParticipante({
                nombreCompleto,
                apellidos, 
                idPerfil,
                correo,
                avatarPerfil,
                idMision,
                fechaRegistro: getUnixTimestamp(),
                tituloMision: titulo
            });

            setInscrito(true)
            setLoading(false);

            Notificacion("success", "Te has inscrito en el proyecto");
            
        } catch (error) {
            console.log(error);
            setLoading(false);

            NotificacionExtendida("error", error.message)
        }
    }

  return (
    <Container>
        <Row className='mb-3'>
            <Col>
                  <Image
                      width="150"
                      height="150"
                      className="mb-4"
                      src={iconoProyecto}
                      alt={"Foto de perfil"}
                  />
                <p><b>"{titulo}"</b></p>
                <pre className='text-start' style={{ whiteSpace: "pre-line" }} >
                    <b><p >{descripcion.replace(/^"|"$/g, '')}</p></b>
                </pre>
            </Col>
        </Row>

        <h3>Recompensas del proyecto:</h3>

        <Row className="justify-content-center mt-3">
            <Col className="col-3">
                <Image fluid width="75" height="75" src={require(`@assets/images/medalla-ganador.png`)} />
                <p>
                   <b> 18,000 Créditos al ganar</b>
                </p>
            </Col>
            <Col className="col-3">
                <Image fluid width="75" height="75" src={require(`@assets/images/medalla-participante.png`)} />
                <p>
                   <b> 6,000 Créditos al participar</b>
                </p>
            </Col>
        </Row>
        
      
        <Row className='mt-4'>
            <Col className='text-start'>
                <h3 className='mb-4'>Revisa tu información antes de inscribirte en el proyecto:</h3>
                <p><b>Nombre:</b> {nombreCompleto + " " + apellidos}</p>
                <p><b>Correo electrónico:</b> {correo}</p>

                <Alert className='mt-4' severity='info'><p>Es importante que revises y confirmes tu información ya que mediante este correo electrónico se te notificará en caso de ganar en la competencia de la misión.</p> Si es necesario actualiza tu información desde la <b>configuración</b> de tu plataforma.</Alert>
            </Col>
        </Row>

        

        <Row className='mt-5'>
            <Col>
                <div className='d-grid gap-2'>
                    <Button disabled={loading} onClick={handleNuevoParticipante}>Participar</Button>
                </div>
            </Col>
        </Row>
    </Container>
  )
}

export default ParticiparMision