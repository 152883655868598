import "@assets/css/components/Home/style.css";

import SeccionSliderHome from "./components/SeccionSliderHome";

import { Link } from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { Animated } from "react-animated-css";
import { useContext, useState } from "react";
import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SeccionPrincipalHome from "./vistasHome/SeccionPrincipalHome";
import ListadoCursos from "../Cursos/ListadoCursos";
import SeccionPrincipalHomeNuevoIngreso from "./vistasHome/SeccionPrincipalHomeNuevoIngreso";
import { getUltimaLeccionTomada } from "@helpers/httpRequests/httpRequestsLecciones/httpRequestsLecciones";
import { getTemas } from "@helpers/httpRequests/httpRequestApariencia/httpRequestApariencia";
import UserContext from "@context/User/UserContext";

export const Home = ({ showScrollButton, handleChangeComponent, handleScrollToTop, handleStartTour }) => {
	const { contextValue, setContextValue } = useContext(UserContext);

	const { usuarioLogeado } = contextValue;

	const { fechaUltimoAcceso } = usuarioLogeado;

	const [temas, setTemas] = useState(["", ""]);

	const [barraSeleccionada, setBarraSeleccionada] = useState("");

	const [nombreBarraSeleccionada, setNombreBarraSeleccionada] = useState("");

	const obtenerTemasApariencia = async () => {
		try {
			const { data } = await getTemas();
			data.map((tema) => {
				if (tema.estatus) {
					if (tema.aparienciaBarraBusqueda !== null) {
						setNombreBarraSeleccionada(tema.nombreTema);
						setBarraSeleccionada(tema.aparienciaBarraBusqueda);
					} else {
						setBarraSeleccionada("");
					}
				}
			});
			setTemas(data);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		obtenerTemasApariencia();

		// loader();
	}, []);

	return (
		<>
			{showScrollButton && (
				<Animated animationIn="fadeIn" isVisible={true} style={{ zIndex: "9999", position: "absolute" }}>
					<div className="scroll-top">
						<span
							className={`rounded-circle scrollTop text-center d-flex`}
							style={{
								height: "50px",
								width: "50px",
								fontSize: "2rem",
								backgroundColor: "#F59E0B",
								position: "fixed",
								bottom: "70px",
								right: "40px",
							}}>
							<Link className="pointer" activeClass="active" to="scrollTopElement" spy={true} smooth={true} duration={800} containerId="scrollTop" style={{ color: "black", width: "100%" }}>
								<FontAwesomeIcon icon={faArrowUp} />
							</Link>
						</span>
					</div>
				</Animated>
			)}

			<Animated animationIn="fadeIn" animationInDuration={250} animationOut="fadeOut" isVisible={true}>
				<div
					style={{
						position: "absolute",
						top: "0",
						left: "0",
						width: "100%",
						zIndex: "20000",
					}}></div>

				<Container fluid className="p-0">
					<Row className="justify-content-center contenedorBarraSeleccionada">
						<Col className="col-12 text-center barraSeleccionada">
							{barraSeleccionada !== "" && (
								<img src={nombreBarraSeleccionada !== "Personalizado" ? require(`@assets/images/${barraSeleccionada}`) : barraSeleccionada} className="img-fluid" width="100%" alt="barraBusqueda" />
							)}
						</Col>
					</Row>

					<Row className="d-none d-md-block">
						<Col>
							<SeccionSliderHome handleChangeComponent={handleChangeComponent} />
						</Col>
					</Row>

					<Row className="mb-4">
						<Col>
							{/* {leccionVista ? (
								<SeccionPrincipalHome handleStartTour={handleStartTour} handleChangeComponent={handleChangeComponent} />
							) : (
								<SeccionPrincipalHomeNuevoIngreso handleStartTour={handleStartTour} handleChangeComponent={handleChangeComponent} />
							)} */}
							
							{(fechaUltimoAcceso !== 0 && fechaUltimoAcceso !== null)? (
								<SeccionPrincipalHome handleStartTour={handleStartTour} handleChangeComponent={handleChangeComponent} />
							) : (
								<SeccionPrincipalHomeNuevoIngreso handleStartTour={handleStartTour} handleChangeComponent={handleChangeComponent} />
							)}
						</Col>
					</Row>

					<Row className="filaCursos">
						<Col>
							{/* <CursosMasVisitadosHome handleChangeComponent={handleChangeComponent} /> */}
							<ListadoCursos handleChangeComponent={handleChangeComponent} />
						</Col>
					</Row>
				</Container>
			</Animated>
		</>
	);
};
