import { Alert } from '@components/general/Alertas/Alertas'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import getUnixTimestamp from '@helpers/dates/getUnixTimestamp/getUnixTimestamp'
import { addSolicitud, sendMailNuevaLeccion } from '@helpers/httpRequests/httpRequestsForoEmpresarial/httpRequestsForoEmpresarial'
import React, { useState } from 'react'
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap'
import ReactInputMask from 'react-input-mask'

const NuevaSolicitud = ({idGrupo, asesor}) => {

    const {nombreCompleto, apellidos, correo} = asesor;

    const [fileBase64, setFileBase64] = useState(null);

    	//STATE PARA LOADER
	const [showLoader, setShowLoader] = useState(false);

    const [formValues, setFormValues] = useState({
        titulo: '',
        descripcion: '',
        contenido: '',
        archivoAdjunto: '',
        telefono: '',
    })

    const [validacionForm, setValidacionForm] = useState({
        titulo: false,
        descripcion: false,
        contenido: false,
        telefono: false,
        archivoAdjunto: false,
        archivoAdjuntoInvalido: false
    })



    const handleOnChange = ({target}) => {
        const {name, value} = target;

        setFormValues({
            ...formValues,
            [name]: value
        })

        if(value === ""){
			setValidacionForm({
				...validacionForm,
				[name]: !validacionForm.name
			});
		}else{
			setValidacionForm({
				...validacionForm,
				[name]: false
			});
		}
    }


    const handleOnChangeFile = (event) => {
        const {name, value} = event.target;

        const file = event.target.files[0]
        // console.log(file);


        setFormValues({
            ...formValues,
            [name]: file
        })

        setValidacionForm({
            ...validacionForm,
            [name]: false
        });


        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
        setFileBase64(reader.result.split(',')[1]);
        };
    }


    const enviarSolicitud = async(e) => {
        e.preventDefault();
        try {
            setShowLoader(true)
            //SE DIVIDE EN DOS, UNA SOLICITUD ENVIA LOS DATOS A LA BASE DE DATOS Y OTRA ENVIA EL ARCHIVO A ALMACENARSE EN SERVIDOR

            const {titulo, descripcion, contenido, archivoAdjunto, telefono} = formValues;
            

            if (titulo === "") {
                // console.log("TITULO");
                setValidacionForm({
                    ...validacionForm,
                    titulo: true,
                });
            } else if (descripcion === "") {
                // console.log("DESCRIPCION");
                setValidacionForm({
                    ...validacionForm,
                    descripcion: true,
                });
            }else if(contenido === ""){
                // console.log("CONTENIDO");
                setValidacionForm({
                    ...validacionForm,
                    contenido: true,
                });
            }else if(archivoAdjunto === ""){
                // console.log("ARCHIVO ADJUNTO");
                setValidacionForm({
                    ...validacionForm,
                    archivoAdjunto: true,
                })
            }else if(telefono === ""){
                setValidacionForm({
                    ...validacionForm,
                    telefono: true,
                })
            }

            
           if(archivoAdjunto !== ""){
        
                
                setValidacionForm({
                    ...validacionForm,
                    archivoAdjuntoInvalido: false
                })
 
               if(titulo !== "" && descripcion !== "" && contenido !== "" && telefono !== ""){
                console.log("Entra para add solicitud")
                 const datosSolicitud= {
                     titulo,
                     descripcion,
                     contenido,
                     fecha: getUnixTimestamp(),
                     archivoAdjunto: archivoAdjunto.name,
                     tipoArchivoAdjunto: archivoAdjunto.type,
                     idGrupo: idGrupo,
                     base64Archivo: fileBase64,
                     nombreAsesor: nombreCompleto + " " + apellidos,
                     correoAsesor: correo,
                     telefono
                 } 
                     
                 const {message} = await addSolicitud(datosSolicitud);

                 console.log(message);

                const {message: respuestaSolicitud} = await sendMailNuevaLeccion(datosSolicitud);
 
                 Alert("Solicitud de nueva lección", "success", respuestaSolicitud)
 
               }
    
     
           }


        } catch (error) {
            console.log(error);
        }
    }


  return (
    <Container>
        <Row>
            <Col className='text-left'>
            
                <Form className='text-start' onSubmit={enviarSolicitud}>

                    <Form.Group className="mb-3">
                        <Form.Label>Titulo de la lección</Form.Label>
                        <Form.Control className={validacionForm.titulo ? 'is-invalid' : ''} value={formValues.titulo} onChange={handleOnChange} name='titulo' type='text' placeholder='¿Cómo se llamará tu lección?'></Form.Control>

                        <Form.Control.Feedback type="invalid">Este campo no puede ir vacío</Form.Control.Feedback>
                    </Form.Group>


                    <Form.Group className="mb-3">
                        <Form.Label>Descripción de la solicitud</Form.Label>
                        <Form.Control className={validacionForm.descripcion ? 'is-invalid' : ''} value={formValues.descripcion} onChange={handleOnChange} name='descripcion' rows={4} as={"textarea"} placeholder="Explicanos de qué hablará tu lección"></Form.Control>

                        <Form.Control.Feedback type="invalid">Este campo no puede ir vacío</Form.Control.Feedback>
                    </Form.Group>


                    <Form.Group className="mb-3">
                        <Form.Label>Contenido de la solicitud</Form.Label>
                        <Form.Control className={validacionForm.contenido ? 'is-invalid' : ''} value={formValues.contenido} onChange={handleOnChange} name='contenido' rows={4} as={"textarea"} placeholder="Detalla lo que se mostrará en tu lección"></Form.Control>

                        <Form.Control.Feedback type="invalid">Este campo no puede ir vacío</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>
                            <h6>Teléfono de contacto</h6>
                        </Form.Label>
                        {/* <Form.Control className='input-form' required onChange={handleOnChange} value={formValues.telefono} name="telefono" type="tel" placeholder="Número de teléfono"></Form.Control> */}

                        <ReactInputMask
                            className={`input-form form-control ${validacionForm.telefono ? 'is-invalid' : ''}`}
                            mask="(999) 999-9999"
                            maskChar=" "
                            required
                            onChange={handleOnChange}
                            value={formValues.telefono}
                            name="telefono"
                            type="tel"
                        />

                        <Form.Control.Feedback type="invalid">
                            <p>Este campo es obligatorio</p>
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className='mb-3'>
                        <Form.Control onChange={handleOnChangeFile} type='file' name='archivoAdjunto' ></Form.Control>

                        {validacionForm.archivoAdjunto && (
                            <p className='text-danger mt-3'><FontAwesomeIcon icon={faExclamationCircle}/> Es necesario adjuntar un archivo para la solicitud</p>
                        )}

                        {validacionForm.archivoAdjuntoInvalido && (
                            <p className='text-danger mt-3'><FontAwesomeIcon icon={faExclamationCircle}/> El archivo que intenta enviar es inválido, intente de nuevo</p>
                        )}
                        <small className='mt-2'>Este archivo es complementario a tu lección y será descargable</small>
                    </Form.Group>

                    

                    <div className="d-grid gap-2">
					<Button  disabled={showLoader ? true: false} type='submit' block>Enviar solicitud {showLoader && (
                        <Spinner animation="border" style={{width: "1rem", height: "1rem", verticalAlign: "middle"}} className={`mx-3${!setShowLoader ? "d-none" : ""}`} />
                    )}</Button>
					</div>

                </Form>
            
            
            </Col>
        </Row>
    </Container>
  )
}

export default NuevaSolicitud