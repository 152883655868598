import { ModalSweetAlert, Notificacion } from '@components/general/Alertas/Alertas';
import { descargarArchivo, getGanadorMision, getTrabajosPorMision } from '@helpers/httpRequests/httpRequestMisiones/httpRequestMisiones'
import React, { useEffect, useState } from 'react'
import { Button, Col, Container, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { FaDownload, FaList, FaMedal } from 'react-icons/fa';
import ConfirmarGanador from './ConfirmarGanador';
import getDateFromUnix from '@helpers/dates/getDateFromUnix/getDateFromUnix';

const ListarParticipantes = ({idMision, handleRegresarParticipantes, infoMision}) => {

    const [participaciones, setParticipaciones]= useState([]);

    const [ganador, setGanador] = useState([]);

    const [ganadorSeleccionado, setGanadorSeleccionado] = useState(false);

    const [showLoader, setShowLoader] = useState(false);
    

    const handleDescargarArchivo = async({currentTarget}) =>{
        const nombreArchivo = currentTarget.id;
        console.log(nombreArchivo)
        try {
            const {data} = await descargarArchivo({
                nombreArchivo
            });

            const dataBinaria = new Uint8Array(data);
      
            const datos = new Blob([dataBinaria]);
      
            var link = document.createElement("a");
      
            link.href = window.URL.createObjectURL(datos);
      
            var fileName = nombreArchivo;
      
            link.download = fileName;
      
            link.click();
        } catch (error) {
            console.log(error)   
            Notificacion("error", error.message)
        }
    }

   


    const  handleSeleccionarGanador = (participacion) => {

        ModalSweetAlert({
            title: "Confirmar ganador",
            html: (
                <ConfirmarGanador participaciones={participaciones} setGanadorSeleccionado={setGanadorSeleccionado} idMision={idMision} infoUsuario={participacion}  handleRegresarParticipantes={handleRegresarParticipantes} infoMision={infoMision}/>
            ),
            showConfirmButton: false,
            showCloseButton: true,
            width: "850px",
        });
    }

    useEffect(() => {
     const trabajosPorMision = async() =>{
        try {
            setShowLoader(true);
            const {data} = await getTrabajosPorMision(idMision)
            console.log(data)
            if(Array.isArray(data)){
                setParticipaciones(data);
            setShowLoader(false);

            }else{
                setParticipaciones([data]);
            setShowLoader(false);

            }
        } catch (error) {
            console.log(error)
        }
     }

     trabajosPorMision()

     const ganadorMision = async() => {
        try {
            const {data} = await getGanadorMision(idMision);
            console.log(data);
            setGanador([data]);
        } catch (error) {
            console.log(error);
            setShowLoader(false)
        }
     }

     ganadorMision()
    }, [])


    useEffect(() => {
     if(ganadorSeleccionado){
        const trabajosPorMision = async() =>{
            try {
                setShowLoader(true);
                const {data} = await getTrabajosPorMision(idMision)
                if(Array.isArray(data)){
                    setParticipaciones(data);
                setShowLoader(false);

                }else{
                    setParticipaciones([data]);
                setShowLoader(false);

                }

            } catch (error) {
                console.log(error)
                setShowLoader(false)
            }
         }
    
         trabajosPorMision()

         
     const ganadorMision = async() => {
        try {
            const {data} = await getGanadorMision(idMision);
            console.log(data);

            setGanador([data]);

        } catch (error) {
            console.log(error);
        }
     }

     ganadorMision()
     }
    }, [ganadorSeleccionado])


    const validarGanador = (idPerfil)=>{
        console.log(ganador)
        const esGanador = ganador.some(ganadors => ganadors.idPerfil === idPerfil);
        console.log(esGanador);
        return esGanador;
    }    
    

  return (
      <Container className='text-start'>
          {participaciones.length > 0 && (
            <Row className='text-center mb-4'>
                <Col>
                    <h4>Participaciones</h4>
                </Col>

                <Col>
                    <h4>Acciones</h4>
                </Col>
            </Row>
          )}
          <Row>
              <Col className='contenedor-participaciones'>

                    {showLoader === true ? (
                      <div className='d-flex justify-content-center mt-4'>
                          <div className="spinner m-3"></div>

                      </div>
                    ): (
                        participaciones.length > 0 ? (
                            participaciones.map((participacion, idx) => (
                                <Row key={idx} className='mb-4 d-flex align-items-center'>
                                    <Col className='col-12 col-lg-8 shadow p-3 card-proyecto'>
                                        <Row>
                                        <Col className='col-9'>
                                            <p><b>{participacion.nombreArchivo}</b></p>
                                            <small>Entregado por: {participacion.nombreCompleto + " " + participacion.apellidos}</small>
                                            <br/>
                                            <small className="mt-4">{getDateFromUnix(participacion.fechaRegistro)}</small>
                                        </Col>
                                        {validarGanador(participacion.idPerfil) && (
                                            <Col className='col-12 col-lg-3 text-start text-lg-center'>
                                                <p className='text-success'>Ganador</p>
                                            </Col>
                                        )}
      
                                        </Row>
                                    </Col>
      
                                    <Col className='col-12 col-lg-4 mt-4 mt-lg-0 text-center'>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id={`tooltip-top`}>Descargar archivo adjunto</Tooltip>}
                                        >
                                          <Button variant='outline-primary' id={participacion.nombreArchivo} onClick={handleDescargarArchivo} className='mx-1'><FaDownload /></Button>
      
                                        </OverlayTrigger>
                                       
                                       {!participacion.tieneGanador && (
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip id={`tooltip-top`}>Seleccionar como ganador</Tooltip>}
                                            >
                                                <Button variant='outline-success' onClick={() => handleSeleccionarGanador(participacion)} className='mx-1'><FaMedal /></Button>
      
                                            </OverlayTrigger>
                                       )}
                                        
                                    </Col>
                                </Row>
                            ))
                        ) : (
                            <Row>
                                <Col className='text-center mt-4 sin-participantes'>
                                    <p>Sin participaciones por el momento</p>
                                </Col>
                            </Row>
                        )
                    )}
                  
              </Col>
          </Row>
      </Container>
  )
}

export default ListarParticipantes