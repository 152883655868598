import DynamicBreadcrumb from "@components/general/DynamicBreadcrumb/DynamicBreadcrumb";
import TitleDashboard from "@components/general/Titles/TitleDashboard";
import Loader from "@helpers/loader/loader";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Animated } from "react-animated-css";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import HistorialPagos from "./components/HistorialPagos";
import LiquidacionAdeudo from "./components/liquidacion/LiquidacionAdeudo";
import RenovacionLicencia from "./components/renovacion/RenovacionLicencia";
import UserContext from "@context/User/UserContext";
import { retrievePaymentIntent } from "@helpers/httpRequests/httpRequestsPagos/httpRequestsPagos";
import { NotificationManager } from "react-notifications";
import EdoCuenta from "./components/edoCuenta/EdoCuenta";

const MisPagos = () => {
	const { contextValue, setContextValue } = useContext(UserContext);

	const { renovacion, usuarioLogeado } = contextValue;

	const { servicioActivo } = usuarioLogeado;

	const [showLoader, setShowLoader] = useState(true);

	const [key, setKey] = useState("edoCuenta");

	const consultarPaymentIntent = async () => {
		try {
			const { paymentIntent } = await retrievePaymentIntent(renovacion.stripeSession.payment_intent);

			if (paymentIntent.status === "succeeded") {
				NotificationManager.success('¡Pago de renovación aplicado!');

				setKey('renovacion');

				setContextValue({
					...contextValue,
					renovacion: {
						...contextValue.renovacion,
						pagoIniciado: false,
						pagoFinalizado: true
					}
				});
			}
		} catch (error) {
			console.log("errpr al consultar el payment intent: ", error);
		}
	};

	useEffect(() => {
		if (renovacion) {
			if (renovacion.pagoIniciado) {
				console.log('consultando payment intent');
				
				consultarPaymentIntent();
			}
		}

		if (!servicioActivo) {
			setKey('renovacion');
		}

		setTimeout(() => setShowLoader(false), 1500);
	}, []);

	return (
		<>
			{showLoader ? (
				<Loader height={"80vh"} setShowLoader={setShowLoader} />
			) : (
				<Animated animationIn="fadeIn" animationInDuration={2000} animationOut="fadeOut" isVisible={true}>
					<Container fluid className="p-0 mb-3">
						<DynamicBreadcrumb arrayBreadcrumbs={[{ title: "Mis Pagos", onClick: () => {} }]} />

						<Row className="mb-3">
							<Col>
								<TitleDashboard title="Mis Pagos" />

								<Tabs className="mt-4" activeKey={key} onSelect={(k) => setKey(k)}>
									{/* <Tab eventKey="historialPagos" title="Historial de Pagos">
										<HistorialPagos />
									</Tab>

									<Tab eventKey="liquidacion" title="Liquidar adeudo">
										<LiquidacionAdeudo />
									</Tab> */}

									<Tab eventKey="edoCuenta" title="Estado de cuenta">
										<EdoCuenta />
									</Tab>

										<Tab eventKey="renovacion" title="Renovar Licencia">
											<RenovacionLicencia />
										</Tab>
								</Tabs>
							</Col>
						</Row>
					</Container>
				</Animated>
			)}
		</>
	);
};

export default MisPagos;
