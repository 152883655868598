import TitleDashboard from "@components/general/Titles/TitleDashboard";
import CardBeneficios from "@components/usuario/CompraLicencia/CardBeneficios";
import DetalleCompra from "@components/usuario/CompraLicencia/DetalleCompra";
import GridPaquetes from "@components/usuario/CompraLicencia/GridPaquetes";
import AvisoPrivacidad from "@components/usuario/CompraLicencia/modals/avisoPrivacidad/AvisoPrivacidad";
import DirectorioTelefonico from "@components/usuario/CompraLicencia/modals/directorioTelefonico/DirectorioTelefonico";
import UserContext from "@context/User/UserContext";
import useWindowResize from "@customHooks/useWindowResize";
import { getPricesCompra, getPricesCompraExterna } from "@helpers/httpRequests/httpRequestsPagos/httpRequestsPagos";
import Loader from "@helpers/loader/loader";
import { Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Card, Col, Container, Image, Navbar, Row } from "react-bootstrap";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

const CompraLicencia = () => {

	//TODO REALIZAR LAS SIGUIENTES VALIDACIONES:
	//*RECUPERAR PARAMETROS DE URL EN CASO DE SER UNA COMPRA SOLICITADA DESDE GLOBALCLICKLATAM.COM
	//*EN BASE A LOS PARAMETROS, SI EXISTEN HACER LAS VALIDACIONES PARA MOSTRAR LA COMPRA EN DOLARES

	const [precios, setPrecios] = useState(null);

	const [showLoader, setShowLoader] = useState(true);

	const [showModalAvisoPrivacidad, setShowModalAvisoPrivacidad] = useState(false);

	const [showModalDirectorioTelefonico, setShowModalDirectorioTelefonico] = useState(false);

	const [compraExterna, setCompraExterna] = useState(false);

	const darkTheme = createTheme({
		palette: {
			mode: "dark",
		},
	});

	const handleClickAvisoPrivacidad = () => {
		setShowModalAvisoPrivacidad(!showModalAvisoPrivacidad);
	};

	const handleClickDirectorioTelefonico = () => {
		setShowModalDirectorioTelefonico(!showModalDirectorioTelefonico);
	};

	const handleClickPreguntasFrecuentes = () => {
		let link = document.createElement("a");

		link.href = "https://globalclickmexico.com/faq/index.html";

		link.target = "_blank";

		link.dispatchEvent(new MouseEvent("click"));
	};

	const { contextValue, setContextValue } = useContext(UserContext);

	const consultarPreciosCompra = async () => {
		try {
			//Se reciben los datos para manejarlos en la vista pública
			const param = new URLSearchParams(window.location.search);

			const datosURL = param.get("new-license");
			//Transformamos el string del data en arreglo
   
			//Si la variable datosURL es diferente de null es una compra fuera de México
			
			let listaPrecios;

			if(datosURL !== null){
				setCompraExterna(true);
				listaPrecios = await getPricesCompraExterna();
			}else{
				listaPrecios = await getPricesCompra();

			}

			//Cuando se asigna a este state se guarda en el contextValue para su uso posterior
			setPrecios(listaPrecios);
		} catch (error) {
			console.log("Error al consultar los precios de la compra: ", error);
		}
	};

	const {
		size: { width },
	} = useWindowResize();

	useEffect(() => {

		if (precios !== null) {
			return setShowLoader(false);
		}

		consultarPreciosCompra();
	}, []);

	useEffect(() => {
		if (precios !== null) {
			setContextValue({
				...contextValue,
				compra: {
					precios,
				},
			});

			setShowLoader(false);
		}
	}, [precios]);

	return (
		<>
			{showLoader ? (
				<Loader />
			) : (
				<ThemeProvider theme={darkTheme}>
					<CssBaseline />

					<div className="bg-main-container" style={{ overflow: "hidden", overflowY: "scroll" }}>
						<Navbar expand="lg" data-bs-theme="dark" bg="dark" className="bg-dark-custom">
							<Container fluid>
								<Navbar.Brand href="#">
									<div className="m-1">
										<img className="pointer" src={require(`@assets/images/logo-gc-horizontal.png`)} alt="Logo Global Click" style={{ width: `${width > 992 ? "10vw" : "20vw"}` }} />
										&nbsp;&nbsp;Compra de Licencia Plataforma Click+
									</div>
								</Navbar.Brand>

								<div className="text-white d-flex justify-content-end">
									<Typography variant="overline" className="pointer px-3 hover-orange" onClick={handleClickAvisoPrivacidad}>
										Aviso de privacidad
									</Typography>

									<Typography variant="overline" className="pointer px-3 hover-orange" onClick={handleClickPreguntasFrecuentes}>
										Preguntas frecuentes
									</Typography>

									<Typography variant="overline" className="pointer px-3 hover-orange" onClick={handleClickDirectorioTelefonico}>
										Directorio telefónico
									</Typography>
								</div>
							</Container>
						</Navbar>

						<Container fluid className="py-30">
							<Row>
								<Col>
									<Container fluid className="bg-light-dark card">
										<Row className="text-white">
											<Col>
												<Container fluid className="py-3">
													<TitleDashboard title="¿Qué cursos vas a adquirir?" />

													<GridPaquetes />

													<TitleDashboard title="¿Algo más?" />

													<CardBeneficios />

													<TitleDashboard title="Detalle de Pago" />

													<DetalleCompra compraExterna={compraExterna} />
												</Container>
											</Col>
										</Row>
									</Container>
								</Col>
							</Row>
						</Container>

						<Container fluid className="p-0">
							<Row>
								<Col>
									<Card>
										<Card.Body className="p-0">
											<Image fluid src={require("@assets/images/compra/compra-footer.jpg")} alt="Footer de Global Click México" className="w-100 h-100" />
										</Card.Body>
									</Card>
								</Col>
							</Row>
						</Container>

						<DirectorioTelefonico modalShow={showModalDirectorioTelefonico} setModalShow={setShowModalDirectorioTelefonico} />

						<AvisoPrivacidad modalShow={showModalAvisoPrivacidad} setModalShow={setShowModalAvisoPrivacidad} /> 
					</div>
				</ThemeProvider>
			)}
		</>
	);
};

export default CompraLicencia;
