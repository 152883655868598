import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "./detalleCompra/header/Header";
import Body from "./detalleCompra/body/Body";
import Footer from "./detalleCompra/footer/Footer";
import UserContext from "@context/User/UserContext";

const DetalleCompra = ({compraExterna}) => {

	const { contextValue, setContextValue } = useContext(UserContext);


	const { compra } = contextValue;
	
	const { precios } = compra;

	return (
		<Row className="py-4">
			<Col className="compra-prices">
				<Container fluid className="detalle-compra">
					<Header  />

					<Body compraExterna={compraExterna} precios={precios} />

					<Footer />
				</Container>
			</Col>
		</Row>
	);
};

export default DetalleCompra;
