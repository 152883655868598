/* eslint-disable react-hooks/exhaustive-deps */
import "@assets/css/views/Dashboard/style.css";
import "react-local-toast/dist/bundle.min.css";
import React, { useContext, useEffect, useState } from "react";

import { LocalToastProvider } from "react-local-toast";
import MultiView from "@components/general/MultiView/MultiView";
import MultiViewProvider from "@context/MultiView/MultiViewProvider";
import NavBar from "@components/general/NavBar/NavBar";
import { ModalSweetAlert, Notificacion } from "@components/general/Alertas/Alertas";
import SiderBarCollapse from "@components/general/SideBarCollapse/SideBarCollapse";
import UserContext from "@context/User/UserContext";
import multiViewComponentsPaths from "@helpers/multiViewComponentsPaths/multiViewComponentsPaths";
import { useProSidebar } from "react-pro-sidebar";
import useWindowResize from "@customHooks/useWindowResize";
import typesTiposUsuario from "@helpers/types/typesTiposUsuario";
import { getDatosUsuario, getTiempoPlataforma, updateStatusTour, updateTiempoEnPlataforma, validateTokenExpiry } from "@helpers/httpRequests/httpRequestsUsuarios/httpRequestsUsuarios";

import { useRef } from "react";
import { getUltimaLeccionTomada } from "@helpers/httpRequests/httpRequestsLecciones/httpRequestsLecciones";
import { useHistory } from "react-router-dom";
import Joyride from "react-joyride";
import Loader from "@helpers/loader/loader";
import { Animated } from "react-animated-css";
import spanishOptionsJoyRide from "@helpers/spanishOptionsJoyRide/spanishOptionsJoyRide";
import { validarVigenciaUsuario } from "@helpers/httpRequests/httpRequestsVigencia/httpRequestsVigencia";
import localStorageItemNames from "@helpers/localStorage/localStorageItemNames";
import dashboardRoutesPaths from "@helpers/dashboardRoutesPaths/dashboardRoutesPaths";
import useSound from "use-sound";
import timesUpSfx from "@components/general/Pomodoro/sounds/timesUp.mp3";
import { NotificationManager } from "react-notifications";
import getUnixTimestamp from "@helpers/dates/getUnixTimestamp/getUnixTimestamp";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import validarCredito from "@helpers/creditoEntretenimiento/validarCredito";
import {
	addUsuarioSinFicha,
	liberarFicha,
	registrarUsoJuegoSalaEntretenimiento,
	registrarVisitaSalaEntretenimiento,
} from "@helpers/httpRequests/httpRequestsSalaEntretenimiento/httpRequestsSalaEntretenimiento";
import injectCSS from "@helpers/injectCSS/injectCSS";
import ModalFestejo from "@helpers/cumpleañosClick/ModalFestejo";
import getDateFromUnix from "@helpers/dates/getDateFromUnix/getDateFromUnix";
import { isMobile } from "@helpers/validaciones/isMobile";
import { getUltimaAgenda } from "@helpers/httpRequests/httpRequestAgendaAsesorias/httpRequestAgendaAsesorias";

const Dashboard = () => {
	const history = useHistory();

	const [lastVisitedComponent, setLastVisitedComponent] = useState(sessionStorage.getItem("lastRoute"));

	const [fichaUsada, setFichaUsada] = useState(false);

	const [fichaDisponible, setFichaDisponible] = useState(false);

	const [isSidebarActive, setIsSidebarActive] = useState(false);

	const [tourActivo, setTourActivo] = useState(false);

	const [currentTourStep, setCurrentTourStep] = useState(false);

	const { contextValue, setContextValue } = useContext(UserContext);

	const { usuarioLogeado } = contextValue;

	const { idPersona, idCredencial, rol, tourVisto, nombreCompleto, idPerfil, usuarioTitular, modoOscuro, servicioActivo, estatusCredencial } = usuarioLogeado;

	const theme = modoOscuro ? "dark" : "light";

	const aplicarTema = async () => {
		injectCSS(modoOscuro ? "assets/themes/sweetalert/dark/theme.css" : "assets/themes/sweetalert/light/theme.css");

		injectCSS(modoOscuro ? "assets/themes/primereact/dark/theme.css" : "assets/themes/primereact/light/theme.css");
	
		window.document.querySelector("html")?.setAttribute("data-bs-theme", theme);
	};

	useEffect(() => {
		aplicarTema();
	}, []);

	// const [activeView, setActiveView] = useState(lastVisitedComponent || rol === typesTiposUsuario.invitado ? multiViewComponentsPaths.homeInvitado : multiViewComponentsPaths.home);
	const [activeView, setActiveView] = useState();

	const [leccionVista, setLeccionVista] = useState(false);

	const [showLoader, setShowLoader] = useState(false);

	// const [vigencia, setVigencia] = useState(false);

	const [temaSeleccionado, setTemaSeleccionado] = useState(false);

	const [modoConcentracion, setModoConcentracion] = useState(false);

	let pomodoroLength = 25;

	let shortBreakLength = 3;

	let longBreakValue = 15;

	const pomodoroValues = JSON.parse(localStorage.getItem("pomodoroValues"));

	if (pomodoroValues !== null) {
		const { pomodoroValue, shortValue, longValue } = pomodoroValues;

		pomodoroLength = pomodoroValue;

		shortBreakLength = shortValue;

		longBreakValue = longValue;
	}

	const [timerMode, setTimerMode] = useState("pomo"); // options: pomo, short, long

	const [pomoLength, setPomoLength] = useState(pomodoroLength);

	const [shortLength, setShortLength] = useState(shortBreakLength);

	const [longLength, setLongLength] = useState(longBreakValue);

	const [secondsLeft, setSecondsLeft] = useState(pomoLength * 60);

	const [tiempoFormateado, setTiempoFormateado] = useState("00:00");

	const [isActive, setIsActive] = useState(false);

	const [buttonText, setButtonText] = useState("INICIAR");

	const [volume, setVolume] = useState(1);

	const [descansoActivo, setDescansoActivo] = useState(false);

	//Cuenta regresiva para la sala de entretenimiento

	const initialTime = 15 * 60; // 15 minutos en segundos

	const [time, setTime] = useState(initialTime);

	const [timesUp] = useSound(timesUpSfx, {
		volume: volume,
	});

	const formatTimeLeft = (seconds) => {
		return `${Math.floor(seconds / 60)}:${seconds % 60 > 9 ? seconds % 60 : "0" + (seconds % 60)}`;
	};

	const calcPercentage = () => {
		if (timerMode === "pomo") {
			return (secondsLeft / (pomoLength * 60)) * 100;
		}
		if (timerMode === "short") {
			return (secondsLeft / (shortLength * 60)) * 100;
		}
		if (timerMode === "long") {
			return (secondsLeft / (longLength * 60)) * 100;
		}
	};

	const {
		size: { width },
	} = useWindowResize();

	const { toggleSidebar, collapseSidebar, broken, rtl } = useProSidebar();

	const joyrideRef = useRef(null);

	const [tourSteps, setTourSteps] = useState([
		{
			target: ".dropdown-user",
			content: `En esta sección podrás consultar y/o modificar los datos de tu perfil. ${
				rol === typesTiposUsuario.estudiante ? "También podrás dar de alta tu segundo usuario o cambiar el perfil de sesión" : ""
			}`,
			disableBeacon: true,
		},
		{
			target: ".search-bar",
			content: "En esta barra de búsqueda podrás encontrar cualquier recurso dentro de la plataforma de una forma más rápida y eficiente.",
			disableBeacon: true,
		},
		{
			target: ".sidebar",
			content: "Dentro de esta barra lateral encontrarás accesos directos a distintas secciones de la plataforma.",
			disableBeacon: true,
			placement: "right",
		},

		{
			target: ".home-icon",
			content: "Por ejemplo, al dar clic en este icono serás dirigido a la página principal donde también podrás encontrar más accesos directos.",
			disableBeacon: true,
			placement: "right",
		},
		{
			target: ".main-slider",
			content: "Dentro de esta sección de noticias encontrarás información sobre posibles promociones, o nuevos cursos agregados a tu plataforma Click +.",
			disableBeacon: true,
			placement: "bottom-start",
		},
		{
			target: ".contenedor-mos",
			content: "En este apartado tienes un acceso directo a tu curso propedéutico para la certificación MOS.",
			disableBeacon: true,
			placement: "right",
		},
		{
			target: ".contenedor-material-capacitacion",
			content: "Aquí podrás encontrar el material visto en tu capacitación.",
			disableBeacon: true,
			placement: "top-start",
		},
		{
			target: ".mis-avances",
			content: "Ten acceso a tu historial de avances de todos los cursos.",
			disableBeacon: true,
			placement: "bottom-start",
		},
		{
			target: ".last-action",
			content: "Retoma tus lecciones desde donde te quedaste la última vez que ingresaste a tu plataforma.",
			disableBeacon: true,
			placement: "bottom-start",
		},
		{
			target: ".examenes-diagnostico",
			content: "Presenta los exámenes diagnóstico para distintos cursos y pon a prueba tus conocimientos.",
			disableBeacon: true,
			placement: "bottom-start",
		},
		{
			target: ".rutas-aprendizaje",
			content: "Optimiza tu curva de aprendizaje tomando algunas de las rutas de aprendizaje predeterminadas o crea una ruta de tu agrado.",
			disableBeacon: true,
			placement: "bottom-start",
		},
		{
			target: ".atajo-pomodoro",
			content: "Utiliza la herramienta Pomodoro para incrementar tu productividad y tu rendimiento durante tus estudios.",
			disableBeacon: true,
			placement: "bottom-start",
		},
		{
			target: ".atajo-juegos",
			content: "Tómate un descanso y visita la sala de entretenimientos.",
			disableBeacon: true,
			placement: "bottom-start",
		},
		{
			target: ".categorias-disponibles",
			content: "Aquí podrás encontrar las categorias de cursos disponibles en tu plataforma.",
			disableBeacon: true,
			placement: "top-start",
		},
		{
			target: ".question",
			content:
				"Por último pero no menos importante, si tienes dudas de cómo interactuar con tu entorno, tendrás este icono de ayuda, el cual te dará un tour por la vista actual para familiarizarte un poco.",
			disableBeacon: true,
			placement: "left-start",
		},
		{
			target: ".end",
			content: "Listo, conoces lo necesario para comenzar a utilizar tu plataforma, suerte en tu aprendizaje.",
			disableBeacon: true,
			placement: "top-start",
		},
	]);

	const [tourStepsFirstTime, setTourStepsFirstTime] = useState([
		{
			target: ".dropdown-user",
			content: `En esta sección podrás consultar y/o modificar los datos de tu perfil. ${
				rol === typesTiposUsuario.estudiante ? "También podrás dar de alta tu segundo usuario o cambiar el perfil de sesión" : ""
			}`,
			disableBeacon: true,
		},
		{
			target: ".search-bar",
			content: "En esta barra de búsqueda podrás encontrar cualquier recurso dentro de la plataforma de una forma más rápida y eficiente.",
			disableBeacon: true,
		},
		{
			target: ".home-icon",
			content: "Por ejemplo, al dar clic en este icono serás dirigido a la página principal donde también podrás encontrar más accesos directos.",
			disableBeacon: true,
			placement: "right",
		},
		{
			target: ".main-slider",
			content: "Dentro de esta sección de noticias encontrarás información sobre posibles promociones, o nuevos cursos agregados a tu plataforma Click +.",
			disableBeacon: true,
			placement: "bottom-start",
		},
		{
			target: ".contenedor-mos",
			content: "En este apartado tienes un acceso directo a tu curso propedéutico para la certificación MOS.",
			disableBeacon: true,
			placement: "top-start",
		},
		{
			target: ".contenedor-material-capacitacion",
			content: "Aquí podrás encontrar el material visto en tu capacitación.",
			disableBeacon: true,
			placement: "top-start",
		},
		{
			target: ".mis-avances",
			content: "Ten acceso a tu historial de avances de todos los cursos.",
			disableBeacon: true,
			placement: "bottom-start",
		},
		{
			target: ".last-action",
			content: "Retoma tus lecciones desde donde te quedaste la última vez que ingresaste a tu plataforma.",
			disableBeacon: true,
			placement: "bottom-start",
		},
		{
			target: ".examenes-diagnostico",
			content: "Presenta los exámenes diagnóstico para distintos cursos y pon a prueba tus conocimientos.",
			disableBeacon: true,
			placement: "bottom-start",
		},
		{
			target: ".rutas-aprendizaje",
			content: "Optimiza tu curva de aprendizaje tomando algunas de las rutas de aprendizaje predeterminadas o crea una ruta de tu agrado.",
			disableBeacon: true,
			placement: "bottom-start",
		},
		{
			target: ".categorias-disponibles",
			content: "Aquí podrás encontrar las categorias de cursos disponibles en tu plataforma.",
			disableBeacon: true,
			placement: "top-start",
		},
		{
			target: ".question",
			content:
				"Por último pero no menos importante, si tienes dudas de cómo interactuar con tu entorno, tendrás este icono de ayuda, el cual te dará un recorrido por la vista actual para familiarizarte un poco.",
			disableBeacon: true,
			placement: "left-start",
		},
		{
			target: ".end",
			content: "Listo, conoces lo necesario para comenzar a utilizar tu plataforma, suerte en tu aprendizaje.",
			disableBeacon: true,
			placement: "top-start",
		},
	]);

	const [runTour, setRunTour] = useState(false);

	const [ultimaAgenda, setUltimaAgenda] = useState({});

	const handleStartTour = async () => {
		try {
			const ultimaLeccion = await getUltimaLeccionTomada(idPerfil);

			if (ultimaLeccion) {
				setLeccionVista(true);
			}
		} catch (error) {
			console.log(error);
		}

		if (servicioActivo) {
			setRunTour(!runTour);
		}
	};

	const cerrarSesion = () => {
		localStorage.removeItem("dataUserLogged");

		localStorage.removeItem("lastRoute");

		localStorage.removeItem("token");

		localStorage.clear();

		sessionStorage.clear();

		// userContext.setStateContext({});

		history.replace("/");
	};

	const handleChangeComponent = async (selectedItem) => {
		try {
			if (estatusCredencial) {
				sessionStorage.setItem("lastRoute", selectedItem);

				setActiveView(selectedItem);

				setShowLoader(true);
			} else {
				sessionStorage.setItem("lastRoute", multiViewComponentsPaths.misPagos);

				setActiveView(multiViewComponentsPaths.misPagos);

				setShowLoader(true);

				// alertaVigencia();
			}
		} catch (e) {
			console.log(e);
		}
	};

	// const registrarUsoComponente = async (view) => {
	// 	try {
	// 			await registrarUso({
	// 				fecha: getUnixTimestamp(),
	// 				componente: view
	// 			});
	// 	} catch (error) {
	// 		console.log(error);
	// 	}
	// };

	// useEffect(() => {

	// 	setTimeout(() => {
	// 		registrarUsoComponente(activeView)
	// 	}, 60000);

	// }, [activeView])

	const handleOnBeforeClose = async (target) => {
		try {
			await updateStatusTour(idPersona);

			const { data } = await getDatosUsuario({
				tipoUsuario: rol,
				idCredencial,
				usuarioTitular,
			});

			const [datosUsuarioActualizados] = data;

			setContextValue({
				...contextValue,
				usuarioLogeado: datosUsuarioActualizados,
			});
		} catch (error) {
			console.log(error);
		}
	};

	const updateBD = async () => {
		try {
			handleOnBeforeClose();
		} catch (error) {
			console.log(error);
		}
	};

	// const alertaVigencia = () => {
	// 	ModalSweetAlert({
	// 		title: `Tu progreso es excelente. No interrumpas tu preparación profesional 😉`,
	// 		html: `
	// 			<Col>
	// 				<span>Tu tiempo de vigencia ha finalizado, pero no te preocupes, ¡tenemos promociones especiales para ti!...</span>
	// 			</Col>
	// 			<Col style="margin-top: 1rem;">
	// 				<span>Comunícate a la siguiente línea y pregunta por tu descuento en renovación.
	// 				</span>
	// 				<p><b>(477) 820-97-74</b></p>
	// 			</Col>
	// 		`,
	// 		showCancelButton: false,
	// 		confirmButtonText: "Entendido",
	// 		confirmButtonColor: "#00152A",
	// 		customClass: {
	// 			popup: "notificacion-tour",
	// 		},
	// 	}).then((isConfirmed) => {
	// 		if (isConfirmed) {
	// 			cerrarSesion();
	// 		}
	// 	});
	// };

	const recuperarFicha = () => {
		setTimeout(async () => {
			NotificationManager.success(`La sala de entretenimiento ya está abierta de nuevo, entra ahora o en otro momento de tu día.`, "Sala de entretenimiento", 20000);
			try {
				await validarCredito(idPerfil);
				setFichaDisponible(true);
			} catch (error) {
				console.log(error.message);
			}
		}, 60 * 60 * 1000); //1 hora en milisegundos
	};

	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);

	const handleShow = () => {
		setShow(true);

		localStorage.setItem("novedades", true);
	};

	const handleVerMisiones = () => {
		handleClose();
		handleChangeComponent("misiones");
	};

	const consultarUltimaAgenda = async () => {
		try {
			const ultimaAgenda = await getUltimaAgenda(idPerfil);

			setUltimaAgenda(ultimaAgenda);
		} catch (error) {
			console.log("Error al consultar la ultima agenda: ", error);

			// NotificationManager.error("No se pudo consultar la última agenda resuelta");
		}
	};

	useEffect(() => {
		// const handleBotonSugerencias = () => {
		// 	let sugerencias = document.getElementById('doorbell-button');

		// 	sugerencias.style.display = modoConcentracion ? 'none' : 'block';
		// }

		// handleBotonSugerencias()

		setShowLoader(true);

		let auxActiveView = "";

		switch (rol) {
			case typesTiposUsuario.invitado:
			case typesTiposUsuario.cortesia:
				auxActiveView = multiViewComponentsPaths.homeInvitado;
				break;

			case typesTiposUsuario.lecturaVeloz:
				auxActiveView = multiViewComponentsPaths.homeLecturaVeloz;
				break;

			default:
				if (!servicioActivo || !estatusCredencial) {
					auxActiveView = multiViewComponentsPaths.misPagos;
				} else {
					auxActiveView = lastVisitedComponent || multiViewComponentsPaths.home;
				}
				break;
		}

		setActiveView(auxActiveView);

		// const verificarVigencia = async () => {
		// 	try {
		// 		const { data: vigencia } = await validarVigenciaUsuario(idCredencial);

		// 		const { estatus } = vigencia;

		// 		if (!estatus) {
		// 			//Retorna la alerta
		// 			alertaVigencia();
		// 		} else {
		// 			setVigencia(true);
		// 		}
		// 	} catch (error) {
		// 		console.log(error);
		// 	}
		// };

		// if (!vigencia) {
		// 	verificarVigencia();
		// }

		const validarExpiracionToken = async () => {
			try {
				const token = localStorage.getItem(localStorageItemNames.token);

				await validateTokenExpiry(token);
			} catch (error) {
				console.log("Error validando la fecha de expiración del token: ", error);

				history.replace(dashboardRoutesPaths.login);
			}
		};

		validarExpiracionToken();

		document.onkeydown = function (e) {
			if (e.ctrlKey && e.keyCode === 85) {
				return false;
			}
		};

		const validarCreditoUsuario = async () => {
			const tieneCredito = await validarCredito(idPerfil);
			if (tieneCredito) {
				// NotificationManager.success(`La sala de entretenimiento se encuentra abierta, entra ahora o en otro momento de tu día.`, "Sala de entretenimiento", 20000);
				try {
					await liberarFicha(idPerfil);
				} catch (error) {
					console.log(error.message);
				}
			}
		};

		validarCreditoUsuario();

		if (!localStorage.getItem("novedades")) {
			handleShow();
		}

		//COMPONENTE PARA ANIVERSARIO DE GLOBAL
		const fechaUnix = getUnixTimestamp();

		const fechaConvertida = getDateFromUnix(fechaUnix);

		// console.log(fechaConvertida)

		if (!localStorage.getItem("festejo") && fechaConvertida === "jueves, 8 de febrero de 2025") {
			setTimeout(() => {
				const mostrarAnuncioFestejo = () => {
					ModalSweetAlert({
						html: <ModalFestejo />,
						showConfirmButton: false,
						showCloseButton: true,
						width: "700px",
					});
				};

				mostrarAnuncioFestejo();

				localStorage.setItem("festejo", true);
			}, 2500);
		}

		if (!estatusCredencial) {
			console.log("mandando a renovacion");

			handleChangeComponent(multiViewComponentsPaths.misPagos);
		}

		if (Object.keys(ultimaAgenda).length === 0) {
			consultarUltimaAgenda();
		}
	}, []);

	useEffect(() => {
		if (Object.keys(ultimaAgenda).length > 0) {
			if (ultimaAgenda !== null) {
				if (ultimaAgenda.feedback === "" || ultimaAgenda.feedback === null) {
					handleChangeComponent(multiViewComponentsPaths.agendasAsesorias);
				}
			}
		}
	}, [ultimaAgenda]);

	useEffect(() => {
		if (estatusCredencial) {
			setTimeout(() => {
				if (tourVisto) {
					return Notificacion("success", `Bienvenido de nuevo ${nombreCompleto}`);
				}

				if (!tourActivo && activeView === multiViewComponentsPaths.home) {
					ModalSweetAlert({
						title: `¡Bienvenido, ${nombreCompleto}!`,
						text: "Te presentamos la plataforma de aprendizaje Click +, a continuación te daremos un recorrido por los componentes principales y te puedas familiarizar un poco antes de comenzar.",
						cancelButtonText: "No quiero ver el tour",
						cancelButtonColor: "#dc3545",
						showCancelButton: true,
						confirmButtonText: "Iniciar tour",
						confirmButtonColor: "#28a745",
						reverseButtons: true,
						customClass: {
							popup: "notificacion-tour",
						},
					}).then((isConfirmed) => {
						if (isConfirmed) {
							handleStartTour();
						}
					});
				}
				setShowLoader(false);
			}, 1200);

			const contador = setInterval(async () => {
				try {
					const { data: vigencia } = await validarVigenciaUsuario(idCredencial);

					const { estatus } = vigencia;

					if (!estatus) {
						cerrarSesion();
					}

					const { data } = await getTiempoPlataforma(idPersona);

					const { tiempoEnPlataforma } = data;

					await updateTiempoEnPlataforma({
						idPersona,
						tiempoActualizado: tiempoEnPlataforma + 1,
					});
				} catch (error) {
					console.log(error);
				}
			}, 60000);
			return () => clearInterval(contador);
		}
	}, [estatusCredencial]);

	useEffect(() => {
		setTimeout(() => {
			setShowLoader(false);
		}, 1500);
	}, [handleChangeComponent]);

	const [pomodorosTomados, setPomodorosTomados] = useState(0);

	useEffect(() => {
		if (isActive) {
			if (timerMode === "short" || timerMode === "long") {
				setDescansoActivo(true);
			}

			const interval = setInterval(() => {
				setSecondsLeft((secondsLeft) => secondsLeft - 1);
			}, 1000);

			if (secondsLeft === 0) {
				clearInterval(interval);
				setIsActive(false);
				setButtonText("");
				timesUp();

				let newTimer = 0;

				if (pomodorosTomados === 4) {
					NotificationManager.info("Es hora de un descanso largo, relajate para continuar con tu aprendizaje, da click aquí para tomar tu descanso", "Tiempo", 10000, () => {
						setDescansoActivo(true);
						setSecondsLeft(longLength * 60);
						setButtonText("PAUSAR");
						setIsActive(true);
						setTimerMode("long");
						setPomodorosTomados(0);

						newTimer = longLength * 60;

						NotificationManager.success(`Descansa durante ${formatTimeLeft(longLength * 60)} ${newTimer > 60 ? "minutos" : "minuto"} para continuar estudiando.`, "Tiempo corriendo", 15000);
					});
				} else {
					if (descansoActivo === true) {
						NotificationManager.info("Es hora de seguir estudiando, da click aquí para finalizar tu descanso", "Tiempo", 10000, () => {
							setDescansoActivo(false);
							setSecondsLeft(pomoLength * 60);
							setButtonText("PAUSAR");
							setIsActive(true);
							setTimerMode("pomo");
							setPomodorosTomados(pomodorosTomados === 0 ? 1 : pomodorosTomados + 1);

							newTimer = pomoLength * 60;

							NotificationManager.success(
								`Continua estudiando durante ${formatTimeLeft(pomoLength * 60)} ${newTimer > 60 ? "minutos" : "minuto"} para tu siguiente descanso.`,
								"Tiempo corriendo",
								15000
							);
						});
					} else {
						NotificationManager.info("Regálate un momento para descansar antes de continuar con tu aprendizaje, haz click aquí para comenzar", "Tomate un descanso", 10000, () => {
							setDescansoActivo(true);
							setSecondsLeft(shortLength * 60);
							setButtonText("PAUSAR");
							setIsActive(true);
							setTimerMode("short");

							newTimer = shortLength * 60;

							NotificationManager.success(`Descansa ${formatTimeLeft(shortLength * 60)} ${newTimer > 60 ? "minutos" : "minuto"} antes de seguir aprendiendo.`, "Momento de relajarse", 15000);
						});
					}
				}
			}

			return () => clearInterval(interval);
		}
	}, [isActive, secondsLeft, timesUp]);

	useEffect(() => {
		if (showLoader === false) {
			if (isMobile.any()) {
				NotificationManager.info("Para una mejor experiencia de usuario te recomendamos entrar a la plataforma desde una computadora portátil o de escritorio");
			}
		}
	}, [showLoader]);

	useEffect(() => {
		if (fichaUsada) {
			const cuentaRegresiva = () => {
				const timer = setInterval(() => {
					setTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
				}, 1000);

				// Limpia el temporizador cuando el componente se desmonta
				return () => clearInterval(timer);
			};

			cuentaRegresiva();

			setTimeout(async () => {
				try {
					const data = await addUsuarioSinFicha({
						idPerfil,
						fechaRegistro: getUnixTimestamp(),
					});

					//Registrar la info del usuario al terminar el tiempo
					const registrarVisitaSala = await registrarVisitaSalaEntretenimiento({
						idPerfil,
						fechaRegistro: getUnixTimestamp(),
					});

					const datosJuego = localStorage.getItem("datosUsoJuego");

					if (datosJuego) {
						const registrarUso = async () => {
							try {
								await registrarUsoJuegoSalaEntretenimiento({
									infoJuegos: JSON.parse(datosJuego),
								});
								localStorage.setItem("datosUsoJuego", []);
							} catch (error) {
								console.log(error.message);
							}
						};

						registrarUso();
					}

					NotificationManager.info("Tu tiempo de juego ha terminado, regresa a la sala de entretenimiento en una hora para obtener otra ficha de juego.");

					setFichaUsada(false);

					recuperarFicha();
				} catch (error) {
					console.log(error);
				}
			}, 15 * 60 * 1000);
		}
	}, [fichaUsada]);

	const darkTheme = createTheme({
		palette: {
			mode: theme,
		},
	});

	return (
		<MultiViewProvider>
			{/* // LocalToastProvider - tooltips en elementos */}
			<LocalToastProvider>
				<ThemeProvider theme={darkTheme}>
					<CssBaseline />

					<div className="parent-container">
						{showLoader ? (
							<Loader height={"80vh"} setShowLoader={setShowLoader} />
						) : (
							<Animated animationIn="fadeIn" animationInDuration={250} animationOut="fadeOut" isVisible={true} className="h-100">
								{/* <Offcanvas placement="start" show={show} onHide={handleClose}>
									<Offcanvas.Header closeButton>
										<Offcanvas.Title>¿Qué hay de nuevo?</Offcanvas.Title>
									</Offcanvas.Header>
									<Offcanvas.Body>
										<Container>
											<Row className="justify-content-center">
												<Col className="text-center col-6">
													<Image fluid src={require(`@assets/images/trofeo.png`)} />
												</Col>
												<Col className="col-12 mt-4">
													<h3 className="text-center">¿Estás listo para una nueva misión?</h3>
													<p>Conoce la nueva sala de misiones Click+, donde puedes participar y ganar créditos que pueden ser usados para canjearlos por recompensas.</p>
												</Col>
												<Col>
													<div className="d-grid gap-2">
														<Button onClick={handleVerMisiones}>Ver misiones</Button>
													</div>
												</Col>
											</Row>
										</Container>
									</Offcanvas.Body>
								</Offcanvas> */}

								<Joyride
									locale={spanishOptionsJoyRide}
									ref={joyrideRef}
									steps={leccionVista ? tourSteps : tourStepsFirstTime}
									run={runTour}
									hideCloseButton
									showSkipButton
									continuous={true}
									disableOverlayClose={false}
									disableCloseOnEsc={false}
									disableScrolling={false}
									scrollToFirstStep={true}
									scrollOffset={100}
									callback={(data) => {
										if (data.status === "finished" || data.status === "skipped") {
											updateBD();
											setRunTour(false);
										}
									}}
									styles={{
										options: {
											primaryColor: "#ff9900",
											textColor: "#00152A",
											width: 900,
											zIndex: 1000,
										},
									}}
								/>

								{modoConcentracion !== true && (
									<NavBar
										className="navbar-gc my-first-step"
										setTemaSeleccionado={setTemaSeleccionado}
										temaSeleccionado={temaSeleccionado}
										handleChangeComponent={handleChangeComponent}
										toggleSidebar={toggleSidebar}
										isSidebarActive={isSidebarActive}
										setIsSidebarActive={setIsSidebarActive}
									/>
								)}

								<div className="d-flex h-100" style={{ direction: rtl ? "rtl" : "ltr" }}>
									{modoConcentracion !== true && (
										<SiderBarCollapse
											activeView={activeView}
											handleChangeComponent={handleChangeComponent}
											isSidebarActive={isSidebarActive}
											setActiveView={setActiveView}
											setIsSidebarActive={setIsSidebarActive}
											rtl={rtl}
										/>
									)}

									<MultiView
										time={time}
										formatTimeLeft={formatTimeLeft}
										calcPercentage={calcPercentage}
										setTimerMode={setTimerMode}
										timerMode={timerMode}
										setPomoLength={setPomoLength}
										pomoLength={pomoLength}
										setShortLength={setShortLength}
										shortLength={shortLength}
										setLongLength={setLongLength}
										longLength={longLength}
										setSecondsLeft={setSecondsLeft}
										secondsLeft={secondsLeft}
										setIsActive={setIsActive}
										isActive={isActive}
										setButtonText={setButtonText}
										buttonText={buttonText}
										timesUp={timesUp}
										volume={volume}
										setVolume={setVolume}
										modoConcentracion={modoConcentracion}
										setModoConcentracion={setModoConcentracion}
										setTemaSeleccionado={setTemaSeleccionado}
										handleStartTour={handleStartTour}
										handleChangeComponent={handleChangeComponent}
										view={activeView}
										fichaUsada={fichaUsada}
										setFichaUsada={setFichaUsada}
										setFichaDisponible={setFichaDisponible}
										fichaDisponible={fichaDisponible}
									/>
								</div>
							</Animated>
						)}
					</div>
				</ThemeProvider>
			</LocalToastProvider>
		</MultiViewProvider>
	);
};

export default Dashboard;
