import React, { forwardRef, useContext, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Button, Card, Col, Container, Image, Ratio, Row } from "react-bootstrap";
import SubtitleDashboard from "@components/general/Titles/SubtitleDashboard";
import Typography from "@mui/material/Typography";
import {
	calcularPromedioPorCursoGrupo,
	generarReporteEmpresarial,
	getCertificacionesMosAprobadasGrupo,
	getCertificadosEmitidosPorCursoGrupo,
} from "@helpers/httpRequests/httpRequestsForoEmpresarial/httpRequestsForoEmpresarial";
import { getCursos } from "@helpers/httpRequests/httpRequestsCursos/httpRequestsCursos";
import { Avatar, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemAvatar, ListItemText, Slide } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { getEmpleadosByGrupo } from "@helpers/httpRequests/httpRequestsUsuarios/empleados/httpRequestsEmpleados";
import minutosAHoras from "@helpers/timer/minutosAHoras";
import getDateFromUnix from "@helpers/dates/getDateFromUnix/getDateFromUnix";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel, faChartColumn, faDownload, faSpinner } from "@fortawesome/free-solid-svg-icons";
import Excel from "exceljs";
import { saveAs } from "file-saver";
import { NotificationManager } from "react-notifications";
import TitleDashboard from "@components/general/Titles/TitleDashboard";
import UserContext from "@context/User/UserContext";
import getUnixTimestamp from "@helpers/dates/getUnixTimestamp/getUnixTimestamp";

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const ReportesEmpresariales = ({ isTabActive, folioContrato, idGrupo, cantidadEstudiantes, cantidadCertificacionesMos }) => {

	const { contextValue, setContextValue } = useContext(UserContext);

	const { usuarioLogeado } = contextValue;

	const { modoOscuro } = usuarioLogeado;

	const cursosInvitado = ["EAUI", "EXUI", "EIUI", "PBIA", "PBUI"];

	const [generandoReporte, setGenerandoReporte] = useState(false);

	const [cursos, setCurso] = useState([]);

	const [empleados, setEmpleados] = useState([]);

	const [promediosCurso, setPromediosCurso] = useState({
		series: [],
		cursos: [],
	});

	const [certificadosCurso, setCertificadosCurso] = useState({
		series: [],
		cursos: [],
	});

	const [certificacionesMos, setCertificacionesMos] = useState({
		porcentaje: [],
	});

	const [showReporte, setShowReporte] = useState(false);

	const [totalCertificadosEmitidos, setTotalCertificadosEmitidos] = useState(0);

	const [showModalDesbloqueoMacros, setShowModalDesbloqueoMacros] = useState(false);

	const showModal = () => {
		setShowModalDesbloqueoMacros(true);
	};

	const closeModal = () => {
		setShowModalDesbloqueoMacros(false);
	};

	const configPromediosCurso = {
		series: [
			{
				name: "Promedio general por curso",
				data: promediosCurso.series.length > 0 ? promediosCurso.series : [],
			},
		],
		options: {
			chart: {
				height: 300,
				type: "bar",
			},
			plotOptions: {
				bar: {
					dataLabels: {
						position: "top",
					},
				},
			},
			dataLabels: {
				enabled: true,
				formatter: (val) => val + "%",
				offsetY: -20,
			},
			xaxis: {
				categories: promediosCurso.cursos,
				position: "bottom",
				axisBorder: { show: false },
				axisTicks: { show: false },
				crosshairs: {
					fill: {
						type: "gradient",
						gradient: {
							colorFrom: "#D8E3F0",
							colorTo: "#BED1E6",
							stops: [0, 100],
							opacityFrom: 0.4,
							opacityTo: 0.5,
						},
					},
				},
				tooltip: {
					enabled: false,
				},
				labels: {
					style: {
						color: '#ffcc00',
					}
				}
			},
			yaxis: {
				axisBorder: {
					show: false,
				},
				axisTicks: {
					show: false,
				},
				labels: {
					show: false,
					formatter: (val) => val + "%",
				},
			},
			theme: {
				mode: modoOscuro ? 'dark' : 'light'
			},
		},
	};

	const configCertificadosCurso = {
		series: [
			{
				name: "Certificados emitidos",
				data: certificadosCurso.series.length > 0 ? certificadosCurso.series : [],
			},
		],
		options: {
			chart: {
				height: 300,
				type: "bar",
			},
			colors: ["#FF9900"],
			dataLabels: {
				enabled: false,
			},
			legend: {
				show: true,
				showForSingleSeries: true,
				customLegendItems: ["Certificados emitidos", "Total máximos"],
				markers: {
					fillColors: ["#FF9900", modoOscuro ? '#fff' : "#00152A"],
				},
			},
			theme: {
				mode: modoOscuro ? 'dark' : 'light'
			},
		},
	};

	const configCertificacionesMosAprobadas = {
		series: [certificacionesMos.porcentaje.length > 0 ? certificacionesMos.porcentaje : []],
		options: {
			chart: {
				type: "radialBar",
				sparkline: {
					enabled: true,
				},
			},
			plotOptions: {
				radialBar: {
					hollow: {
						margin: 15,
						size: "70%",
					},
					dataLabels: {
						showOn: "always",
						name: {
							offsetY: -10,
							show: true,
							// color: "#888",
							fontSize: "13px",
						},
						value: {
							color: modoOscuro ? '#fff' : "#000",
							fontSize: "30px",
							show: true,
						},
					},
				},
			},
			stroke: {
				lineCap: "round",
			},
			labels: ["Progreso de Certificaciones MOS"],
			theme: {
				mode: modoOscuro ? 'dark' : 'light'
			},
		},
	};

	const consultarCursos = async () => {
		try {
			let { data: cursos } = await getCursos();

			if (!Array.isArray(cursos)) {
				cursos = [cursos];
			}

			setCurso(cursos);
		} catch (error) {
			console.log("error al consultar los cursos: ", error);
		}
	};

	const consultarEmpleados = async () => {
		try {
			let { data: empleados } = await getEmpleadosByGrupo(idGrupo);

			if (!Array.isArray(empleados)) {
				empleados = [empleados];
			}

			setEmpleados(empleados);
		} catch (error) {
			console.log("error al consultar a los empleados del grupo: ", error);
		}
	};

	const consultarPromedioPorCursoGrupo = async () => {
		try {
			const { data: promediosPorCurso } = await calcularPromedioPorCursoGrupo({ idGrupo });
	
			const seriesAux = [];

			const cursosAux = [];

			for (const curso in cursos) {
				if (Object.hasOwnProperty.call(cursos, curso)) {
					const { nombre, codigoCurso, tieneEvaluacion } = cursos[curso];

					if (!cursosInvitado.includes(codigoCurso)) {
						if (tieneEvaluacion) {
							if (codigoCurso === "PNLB") {
								cursosAux.push("PNL");
							} else {
								cursosAux.push(nombre);
							}

							const promedioCurso = promediosPorCurso.find((promedio) => promedio.codigoCurso === codigoCurso);

							if (promedioCurso !== undefined) {
								const promedioGeneral = promedioCurso.promedios.reduce((total, numero) => total + numero, 0) / promedioCurso.promedios.length;

								seriesAux.push(promedioGeneral.toFixed(1));
							} else {
								seriesAux.push(0);
							}
						}
					}
				}
			}

			setPromediosCurso({
				series: seriesAux,
				cursos: cursosAux,
			});
		} catch (error) {
			console.log("error al consultar las certificaciones: ", error);
		}
	};

	const consultarCertificadosEmitidosPorCursoGrupo = async () => {
		try {
			const { data: certificadosPorCurso } = await getCertificadosEmitidosPorCursoGrupo({ idGrupo });

			const seriesAux = [];

			const cursosAux = [];

			let totalCertificados = 0;

			for (const curso in cursos) {
				if (Object.hasOwnProperty.call(cursos, curso)) {
					const { nombre, codigoCurso, tieneEvaluacion } = cursos[curso];

					if (!cursosInvitado.includes(codigoCurso)) {
						if (tieneEvaluacion) {
							if (codigoCurso === "PNLB") {
								cursosAux.push("PNL");
							} else {
								cursosAux.push(nombre);
							}

							const certificadosCurso = certificadosPorCurso.find((certificadoCurso) => certificadoCurso.codigoCurso === codigoCurso);

							seriesAux.push({
								x: codigoCurso === "PNLB" ? "PNL" : nombre,
								y: certificadosCurso !== undefined ? certificadosCurso.cantidadCertificados : 0,
								goals: [
									{
										name: "Máximos",
										value: cantidadEstudiantes,
										strokeHeight: 5,
										strokeColor: "#00152A",
									},
								],
							});

							if(certificadosCurso !==undefined ) {
								totalCertificados += certificadosCurso.cantidadCertificados;
							}
						}
					}
				}
			}
			console.log({
				series: seriesAux,
				cursos: cursosAux,
			})

			setTotalCertificadosEmitidos(totalCertificados);

			setCertificadosCurso({
				series: seriesAux,
				cursos: cursosAux,
			});
		} catch (error) {
			console.log("error al consultar los certificados emitidos por curso del grupo: ", error);
		}
	};

	const consultarCertificacionesMosAprobadas = async () => {
		try {
			const { data: certificaciones } = await getCertificacionesMosAprobadasGrupo({ idGrupo });

			let porcentajeCubierto = 0;

			if (certificaciones.length > 0) {
				porcentajeCubierto = (certificaciones.length * 100) / cantidadCertificacionesMos;
			}

			setCertificacionesMos({
				porcentaje: [porcentajeCubierto],
				certificaciones,
			});
		} catch (error) {
			console.log("error al consultar las certificaciones mos aprobadas: ", error);
		}
	};

	const fillXlsxTables = () => {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await fetch(`${window.location.origin}/assets/templates/plantilla_reporte_empresarial.xlsm`);

				const templateBytes = await data.arrayBuffer();

				const workbook = new Excel.Workbook();

				await workbook.xlsx.load(templateBytes);

				const hojaPromedioGeneralCursos = workbook.getWorksheet("Promedio General p-curso");

				const hojaColaboradores = workbook.getWorksheet("Actividad de Colaboradores");

				const hojaCertificadosEmitidosCursos = workbook.getWorksheet("Certificados emitidos p-curso");

				const hojaCertificacionesMos = workbook.getWorksheet("Certificaciones MOS");

				const tablePromedioGeneralConfig = {
					name: "tblPromedioGeneral",
					ref: "A1",
					headerRow: true,
					columns: [{ name: "Curso" }, { name: "Promedio" }],
					rows: promediosCurso.series.map((promedio, idx) => [promediosCurso.cursos.find((c, idxc) => idxc === idx), typeof promedio === "string" ? parseFloat(promedio) : promedio]),
				};

				const tableColaboradoresConfig = {
					name: "tblColaboradores",
					ref: "A1",
					headerRow: true,
					columns: [{ name: "Nombre" }, { name: "Último ingreso" }, { name: "Tiempo en plataforma" }],
					rows: empleados.map(({ fechaUltimoAcceso, nombreCompleto, apellidos, tiempoEnPlataforma }, idx) => [
						`${nombreCompleto} ${apellidos}`,
						fechaUltimoAcceso === null ? "Sin accesos" : getDateFromUnix(fechaUltimoAcceso),
						tiempoEnPlataforma === null ? "Sin tiempo registrado" : minutosAHoras(tiempoEnPlataforma),
					]),
				};

				const tableCertificadosEmitidosCursosConfig = {
					name: "tblCertificadosEmitidos",
					ref: "A1",
					headerRow: true,
					columns: [{ name: "Curso" }, { name: "Certificados emitidos" }, { name: "Certificados emitibles" }, { name: "Progreso" }, { name: "Restante" }],
					rows: certificadosCurso.series.map((certificadoEmitido, idx) => [
						certificadosCurso.cursos.find((c, idxc) => idxc === idx),
						certificadoEmitido.y,
						cantidadEstudiantes,
						parseFloat(Math.round(((certificadoEmitido.y * 100) / cantidadEstudiantes).toFixed(1))),
						parseFloat(Math.round((100 - (certificadoEmitido.y * 100) / cantidadEstudiantes).toFixed(1))),
					]),
				};

				const tableCertificacionesMos = {
					name: "tblCertificacionesMos",
					ref: "A1",
					headerRow: true,
					columns: [{ name: "Máximo total" }, { name: "Certificaciones Aprobadas" }, { name: "Progreso" }, { name: "Restante" }],
					rows: [
						[
							cantidadCertificacionesMos,
							(certificacionesMos.porcentaje[0] * cantidadCertificacionesMos) / 100,
							certificacionesMos.porcentaje[0] * 0.01,
							(100 - certificacionesMos.porcentaje[0]) * 0.01,
						],
					],
				};

				hojaPromedioGeneralCursos.addTable(tablePromedioGeneralConfig);

				hojaColaboradores.addTable(tableColaboradoresConfig);

				hojaCertificadosEmitidosCursos.addTable(tableCertificadosEmitidosCursosConfig);

				hojaCertificacionesMos.addTable(tableCertificacionesMos);

				const buffer = await workbook.xlsx.writeBuffer();

				resolve(buffer);
			} catch (error) {
				console.log("error al exportar el reporte", error);

				reject(error);
			}
		});
	};

	const handleExport = async () => {
		try {
			setGenerandoReporte(true);

			NotificationManager.info("Generando reporte, espera un momento...");

			const buffer = await fillXlsxTables();

			const formData = new FormData();

			formData.append("xlsx", new Blob([buffer]));

			const reporte = await generarReporteEmpresarial(formData, folioContrato);

			const byteCharacters = atob(reporte);

			const byteNumbers = new Array(byteCharacters.length);

			for (let i = 0; i < byteCharacters.length; i++) {
				byteNumbers[i] = byteCharacters.charCodeAt(i);
			}

			const byteArray = new Uint8Array(byteNumbers);

			const blob = new Blob([byteArray], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

			setGenerandoReporte(false);

			NotificationManager.success("¡Reporte generado!");

			saveAs(blob, `reporte_empresarial_${folioContrato}.xlsm`);

			showModal();
		} catch (error) {
			console.log("error al exportar el reporte", error);

			setGenerandoReporte(false);

			NotificationManager.error("Algo sucedió al generar el reporte, vuelve a intentar");
		}
	};

	const handlePDF = async () => {
		setShowReporte(!showReporte);
	}

	const descargarPDF = () => {
	
	try {
			const html2pdf = require('html2pdf.js');
			var element = document.getElementById('reporteEmpresarial');
			html2pdf(element, {
				filename: `Reporte_empresarial_${getUnixTimestamp()}`
			});
		}catch(e) {
			console.log(e);
		}
	
	}

	useEffect(() => {
		if (cursos.length === 0) {
			consultarCursos();
		}

		if (cursos.length > 0) {
			consultarEmpleados();

			consultarPromedioPorCursoGrupo();

			consultarCertificadosEmitidosPorCursoGrupo();

			consultarCertificacionesMosAprobadas();
		}
	}, [cursos]);

	return (
		isTabActive && (
			<Container className="my-3" >
				<SubtitleDashboard title="Reporte General" />

				<Row className="justify-content-end">
					<Col className="col-auto">
						<Button className={`my-4 btn ${showReporte ? 'btn-danger' : 'btn-primary'}`} onClick={handlePDF}>{showReporte ? "Cancelar" : "Generar Reporte"} <FontAwesomeIcon icon={showReporte ? faCancel : faChartColumn} /></Button>
					</Col>
				</Row>
				{/* <Row>
					<Col align="right">
						<Button variant="success" type="button" onClick={handleExport}>
							Exportar reporte {generandoReporte ? <FontAwesomeIcon spin icon={faSpinner} /> : <FontAwesomeIcon icon={faDownload} />}
						</Button>
					</Col>
				</Row> */}

				{!showReporte ? (
					<Row>
						<Col className="mt-3">
							<Row id="reporteEmpresarial" className="mx-2 justify-content-center">

								<Col className="col-10 mb-2">
									<Typography variant="h6">Promedio General p/curso</Typography>
									<br />
									<Typography variant="caption">Este es un promedio calculado a partir del puntaje obtenido de todos los colaboradores que han completado el curso</Typography>

									<ReactApexChart
										className="w-100"
										options={configPromediosCurso.options}
										series={configPromediosCurso.series}
										type="bar"
										height={350}
									/>
								</Col>

								{certificadosCurso.series.length > 0 ? (
									<Col className="col-10 mb-0">
										<Typography variant="h6">Certificados emitidos p/curso</Typography>
										<br />
										<Typography variant="caption">Este es un valor calculado a partir de la cantidad de Certificados emitidos por los colaboradores que han completado el curso</Typography>

										<ReactApexChart
											options={configCertificadosCurso.options}
											series={configCertificadosCurso.series}
											type="bar"
											height={350}
											className="w-100"
										/>
									</Col>
								) : null}

							</Row>
							{/* 
						<Row >
							<Col className="col-10 mb-5">
								<Typography variant="h6">Certificados emitidos p/curso</Typography>
								<br />
								<Typography variant="caption">Este es un valor calculado a partir de la cantidad de Certificados emitidos por los colaboradores que han completado el curso</Typography>

								<ReactApexChart
									options={configCertificadosCurso.options}
									series={configCertificadosCurso.series}
									type="bar"
									height={400}
									className="w-100 "
								/>
							</Col>
						</Row> */}

							<Row className="mt-5 p-4 justify-content-center">
								<Col xs={10} className="mb-5">
									<Typography variant="h6">Certificaciones MOS Aprobadas</Typography>
									<br />
									<Typography variant="caption">
										Este es un valor calculado a partir de la cantidad de Certificaciones MOS a las que tu empresa tiene derecho. Actualmente cuentas con <strong>{cantidadCertificacionesMos}</strong>{" "}
										adquiridas.
									</Typography>

									<ReactApexChart options={configCertificacionesMosAprobadas.options} series={configCertificacionesMosAprobadas.series} type="radialBar" height={400} />
								</Col>

								<Col xs={10} className="mb-5">
									<Typography variant="h6">Actividad de Colaboradores</Typography>
									<br />
									<Typography variant="caption">
										Aquí podrás visualizar la última fecha de ingreso y el tiempo dentro de la plataforma de cada colaborador. Es posible que haya registros con fecha de ingreso pero no tiempo
										registrado, para ello los colaboradores deben pasar más de un minuto dentro de la plataforma.
									</Typography>

									<Card body className="mt-3">
										<div className="actividad-empleados">
											<List>
												{empleados.map(({ fechaUltimoAcceso, nombreCompleto, apellidos, tiempoEnPlataforma }, idx) => (
													<ListItem key={idx} disableGutters secondaryAction={tiempoEnPlataforma === null ? "Sin tiempo registrado" : minutosAHoras(tiempoEnPlataforma)}>
														<ListItemAvatar>
															<Avatar>
																<AccountCircleIcon />
															</Avatar>
														</ListItemAvatar>

														<ListItemText primary={`${nombreCompleto} ${apellidos}`} secondary={`Ultimo ingreso: ${fechaUltimoAcceso === null ? "Sin accesos" : getDateFromUnix(fechaUltimoAcceso)}`} />
													</ListItem>
												))}
											</List>
										</div>
									</Card>
								</Col>
							</Row>
						</Col>
					</Row>
				) : null}


				{showReporte ? (
					<>
						<Row className="justify-content-center">
							<Col className="col-auto mb-4">
								<Button className={'btn-success'} onClick={descargarPDF}>Descargar <FontAwesomeIcon icon={faDownload} /> </Button>
							</Col>
						</Row>
						<Row className="mb-3">
							<Col>
								<span>Vista previa del reporte</span>
							</Col>
						</Row>
						<Row style={{ border: '1px solid #212529', borderRadius: 15 }}   className="mx-2 justify-content-center  shadow-lg">
							<Col className="col-12 px-3" id="reporteEmpresarial">
								<Col className="col-12 mb-1">
									<Image src={require("@assets/images/click.png")} width="175" />
								</Col>
								<Col className="col-12" style={{marginBottom: 15}}>
									<TitleDashboard  title={"Resumen"}/>

									<Row className="mt-3">
										<Col>
											<p>Colaboradores registrados en el grupo: {empleados.length > 0 ? <b>{empleados.length}</b> : "Sin colaboradores registrados"}</p>

											<p>Total de certificaciones emitidas en el grupo: <b>{totalCertificadosEmitidos}</b></p>
										</Col>
									</Row>
								</Col>
								<Col className="col-12">
									<Typography variant="h6">Promedio General p/curso</Typography>
									<br />
									<Typography variant="caption">Este es un promedio calculado a partir del puntaje obtenido de todos los colaboradores que han completado el curso</Typography>

									<ReactApexChart
										className="w-50"
										options={configPromediosCurso.options}
										series={configPromediosCurso.series}
										type="bar"
										height={350}
									/>
								</Col>

								<Col className="col-12 mb-0" >
									<Typography variant="h6">Certificados emitidos p/curso</Typography>
									<br />
									<Typography variant="caption">Este es un valor calculado a partir de la cantidad de Certificados emitidos por los colaboradores que han completado el curso</Typography>

									<ReactApexChart
										options={configCertificadosCurso.options}
										series={configCertificadosCurso.series}
										type="bar"
										height={350}
										className="w-50"
									/>
								</Col>

								
							</Col>

							
						</Row>

						
					</>
				) : null}

				<Dialog open={showModalDesbloqueoMacros} TransitionComponent={Transition} keepMounted onClose={closeModal} aria-describedby="alert-dialog-slide-description" fullWidth={true} maxWidth="lg">
					<DialogContent>
						<Container>
							<TitleDashboard title="¿Cómo abrir tu reporte?" />
							<br />
							<Typography gutterBottom variant="body1">
								Ve el siguiente vídeo tutorial para aprender a activar tu reporte empresarial
							</Typography>
							<br />

							<Row>
								<Col>
									<Ratio aspectRatio="16x9">
										<iframe
											width="100%"
											height="100%"
											src="https://player.vimeo.com/video/912697970?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
											allow="autoplay; fullscreen; picture-in-picture"
											allowFullScreen
											title="Activación de reporte empresarial"
										/>
									</Ratio>
								</Col>
							</Row>
						</Container>
					</DialogContent>

					<DialogActions>
						<Button variant="primary" onClick={closeModal}>
							Entendido
						</Button>
					</DialogActions>
				</Dialog>
			</Container>
		)
	);
};

export default ReportesEmpresariales;
