import { Notificacion } from "@components/general/Alertas/Alertas";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { updateNombreUsuario, updatePerfil } from "@helpers/httpRequests/httpRequestsUsuarios/httpRequestsUsuarios";
import { validateDisponibilidadNombreUsuario } from "@helpers/httpRequests/httpRequestsValidaciones/httpRequestsValidaciones";
import regExpStrings from "@helpers/regExpStrings/regExpStrings";
import React, { useState } from "react";
import { Alert, Button, Col, Container, FloatingLabel, Form, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";

const EditarUsuario = ({ usuario }) => {
	const [formValues, setFormValues] = useState(usuario);

	const [validacionCorreo, setValidacionCorreo] = useState(true);

	const [validacionNombreUsuario, setValidacionNombreUsuario] = useState(true);

	const [loading, setLoading] = useState(false);

	const handleOnChange = async ({ target }) => {
		try {
			setFormValues({
				...formValues,
				[target.name]: target.value,
			});

			switch (true) {
				case target.name === "correo":
					setValidacionCorreo(regExpStrings.regexEmail(formValues.correo));
					break;

				case target.name === "nombreUsuario":
					if (target.value === "") {
						return setValidacionNombreUsuario(false);
					}

					if (target.value === usuario.nombreUsuario) {
						return setValidacionNombreUsuario(true);
					}

					if (target.value.length < 8) {
						return setValidacionNombreUsuario(false);
					}

					try {
						const { data: validacionNombreUsuario } = await validateDisponibilidadNombreUsuario(target.value);

						setValidacionNombreUsuario(validacionNombreUsuario);
					} catch (error) {
						const { data: usuarioNoDisponible } = error;

						setValidacionNombreUsuario(usuarioNoDisponible);
					}
					break;

				default:
					break;
			}
		} catch (error) {
			console.log("Error al establecer los valores del formulario: ", error);
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			if (!regExpStrings.regexEmail(formValues.correo)) {
				setValidacionCorreo(false);

				return NotificationManager.error("Ingresa un correo válido");
			}

			if (formValues.nombreUsuario !== usuario.nombreUsuario) {
				await updateNombreUsuario({
					nombreUsuario: formValues.nombreUsuario,
					idCredencial: usuario.idCredencial,
				});
			}

			setLoading(true);

			await updatePerfil(formValues);

			Notificacion("success", "Los datos del usuario se actualizaron correctamente.");

			setLoading(false);

			// setUsuarioEditado(true);
		} catch (error) {
			console.log("Error al actualizar los datos del asesor: ", error);

			if (error.code === 500) {
				const { data } = error;

				if (data.code === "ER_DUP_ENTRY") {
					setValidacionCorreo(false);

					return NotificationManager.error("El correo ingresado ya está siendo utilizado, ingresa un correo distinto");
				}
			}

			NotificationManager.error("No se pudo actualizar al asesor. \n" + error.message);
		}
	};

	return (
		<Form onSubmit={handleSubmit}>
			<Container fluid className="text-start">
				<Row>
					<FloatingLabel as={Col} label="Nombre(s)" className="mb-3">
                        <Form.Control onChange={handleOnChange} name="nombreCompleto" type="text" placeholder="Escribe tu nombre(s)" value={formValues.nombreCompleto} autoComplete="off" />
					</FloatingLabel>
				</Row>

				<Row>
                    <FloatingLabel as={Col} label="Apellidos" className="mb-3">
                        <Form.Control onChange={handleOnChange} name="apellidos" type="text" placeholder="Escribe tus apellidos" value={formValues.apellidos} autoComplete="off" />
					</FloatingLabel>
				</Row>

				<Row>
                    
                    <FloatingLabel as={Col} label='Nombre de usuario' className='mb-3'>

						<Form.Control
							className={validacionNombreUsuario ? "is-valid" : "is-invalid"}
							onChange={handleOnChange}
							name="nombreUsuario"
							type="text"
							placeholder="Escribe tu nombre de usuario"
							value={formValues.nombreUsuario}
							autoComplete="off"
						/>

                    </FloatingLabel>

                    {!validacionNombreUsuario && (
                        <Alert variant="danger" className="mt-3">
                            El nombre de usuario debe tener al menos 8 caracteres
                        </Alert>
                    )}
                    
				</Row>

				<Row>
                    <FloatingLabel as={Col} label='Correo electrónico' className='mb-3'>
						<Form.Control
							className={validacionCorreo ? "is-valid" : "is-invalid"}
							onChange={handleOnChange}
							name="correo"
							type="text"
							placeholder="Escribe tu correo"
							value={formValues.correo}
							autoComplete="off"
						/>
                    </FloatingLabel>
				</Row>

				<Row>
                    <FloatingLabel as={Col} label='CURP' className='mb-3'>
						<Form.Control onChange={handleOnChange} name="curp" type="text" placeholder="Escribe tu curp" value={formValues.curp} autoComplete="off" />
                    </FloatingLabel>

                    <FloatingLabel as={Col} label='Profesión' className='mb-3'>
						<Form.Control onChange={handleOnChange} name="profesion" type="text" placeholder="Ingresa tu profesion" value={formValues.profesion} autoComplete="off" />
                    </FloatingLabel>
				</Row>

				<Row>
					<Col>
						<FloatingLabel as={Col} label='Número de teléfono' className='mb-3'>
							<Form.Control onChange={handleOnChange} name="numeroTelefono" type="text" placeholder="Escribe un número de teléfono" value={formValues.numeroTelefono} autoComplete="off" />
						</FloatingLabel>
					</Col>
				</Row>

				<Row>
					<Col>
						<div className="d-grid gap-2 mt-4">
							<Button type="submit" variant="success" size="lg">
								Editar información {loading ? <FontAwesomeIcon spin icon={faSpinner} /> : null}
							</Button>
						</div>
					</Col>
				</Row>
			</Container>
		</Form>
	);
};

export default EditarUsuario;
