import React from "react";
import TotalAPagarListItem from "./items/TotalAPagarListItem";
import numberCommaSeparator from "@helpers/formateoPrecios/numberCommaSeparator";

const TotalAPagarList = ({ contado, credito, msi, codigoAplicado, compraExterna }) => {
	return (
		<ul id="listPrecios ms-5">
			<TotalAPagarListItem codigoAplicado={codigoAplicado} content={<>De contado: $ {numberCommaSeparator(contado)} {compraExterna ? "USD" : "MXN"}</>} isCredito={false} />

			{!compraExterna && (
				<TotalAPagarListItem
					codigoAplicado={codigoAplicado}
					content={
						<>
							De&nbsp;
							<strong>{msi.planes[0]}</strong>&nbsp;a&nbsp;
							<strong>{msi.planes[msi.planes.length - 1]}</strong>&nbsp; Meses sin intereses: $ {numberCommaSeparator(msi.monto)} {compraExterna ? "USD" : "MXN"}
						</>
					}
					isCredito={false}
				/>
			)}

			<TotalAPagarListItem
				codigoAplicado={codigoAplicado}
				content={{
					li: `A Crédito: $ ${numberCommaSeparator(credito.precioTotal)} ${compraExterna ? "USD" : "MXN"}`,
					ul: (
						<ul id="listDetallePreciosCredito">
							<li className="orange-gc">
								<span className={codigoAplicado ? "strike" : ""}>
									<strong>{credito.totalPagosQuincenales}</strong>
									&nbsp;Pagos quincenales: $ {numberCommaSeparator(credito.pagoQuincenal)} {compraExterna ? "USD" : "MXN"}
								</span>
							</li>

							<li className="orange-gc">
								<span className={codigoAplicado ? "strike" : ""}>Enganche: $ {numberCommaSeparator(credito.pagoInicial)} {compraExterna ? "USD" : "MXN"}</span>
							</li>
						</ul>
					),
				}}
				isCredito={true}
			/>
		</ul>
	);
};

export default TotalAPagarList;
