const dashboardRoutesPaths = {
  certificado: "/certificado",
  dashboard: "/dashboard",
  default: "/",
  formularioRegistroSai: '/registro-en-linea/:folioContrato',
  intereses: "/intereses",
  listadoCursos: "/listado-cursos",
  login: "/",
  pagos: {
    abonoRealizadoLoggedIn: "/abono-realizado",
    abonoRealizado: "/public-abono-realizado",
    abonoLicencia: '/abono-licencia',
    abonoLicenciaExterno: '/abono-licencia?license-payment=true',
    compra: {
      compraCertificacionMos: "/compra-certificacion-mos",
      compraCertificacionMosRealizadaPublica: "/compra-certificacion-mos-realizada",
      compraCertificacionMosExternaRealizadaPublica: "/compra-certificacion-mos-realizada?external=true",
      compraCertificacionMosRealizada: "/certificacion-mos-adquirida",
      compraFinalizada: "/compra-finalizada",
      compraLicencia: "/comprar-licencia",
      compraLink: "/compra-rapida/:msi?",
      compraLinkFinalizada: "/compra-rapida-finalizada",
      compraRealizada: "/compra-realizada",
      compraLecturaVelozRealizada: "/compra-realizada-lectura-veloz",
    },
    liquidacionRealizadaLoggedIn: "/liquidacion-realizada",

    liquidacionRealizada: "/public-liquidacion-realizada",
    liquidacionLicencia: '/liquidacion-licencia',
    liquidacionLicenciaExterna: '/liquidacion-licencia?total-payment=true',

    
    renovacion: {
      renovacionLicencia: "/renovar-licencia",
      renovacionRealizada: "/renovacion-realizada",
    }
  },
  perfiles: "/perfiles",
  preRegistro: "/pre-registro",
  rawFormularioRegistroSai: '/registro-en-linea',
  recuperacionCredenciales: "/recuperacion-credenciales",
  recuperacionNombreUsuario: "/recuperacion-usuario",
  registroEmpleado: "/registro-empleado",
  root: "/",
  routeLearningView: "/rutas-de-aprendizaje",
  usuarioLogueadoHome: "/home-inicial",
  examenDiagnosticoLecturaVeloz: '/examen-diagnostico-lectura-veloz',
  formularioRecuperacionContrasenia: '/reset-password',
  formularioRecuperacionNombreUsuario: '/recover-user',
  reproductorAudio: '/track-player'
};

export default dashboardRoutesPaths;
