import UserContext from "@context/User/UserContext";
import { faEdit, faQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { Button, Col, Container, OverlayTrigger, Row, Tab, Tabs, Tooltip } from "react-bootstrap";
import { ModalSweetAlert } from "../Alertas/Alertas";
import TitleDashboard from "../Titles/TitleDashboard";
import Publicaciones from "./Componentes/Publicaciones";
import EditarGrupo from "./Modales/EditarGrupo";

import "@assets/css/components/ForoEmpresarial/style.css";
import { getGrupoById } from "@helpers/httpRequests/httpRequestsGrupos/httpRequestsGrupos";
import { useState } from "react";

import Loader from "@helpers/loader/loader";

import { Animated } from "react-animated-css";
import Lecciones from "./Componentes/Lecciones";
import Colaboradores from "./Componentes/Colaboradores";
import DynamicBreadcrumb from "../DynamicBreadcrumb/DynamicBreadcrumb";
import typesTiposUsuario from "@helpers/types/typesTiposUsuario";
import useWindowResize from "@customHooks/useWindowResize";

import Joyride from "react-joyride";
import spanishOptionsJoyRide from "@helpers/spanishOptionsJoyRide/spanishOptionsJoyRide";
import CertificacionesMos from "./Componentes/CertificacionesMos";
import ReportesEmpresariales from "./Componentes/ReportesEmpresariales";
import { Box, Modal, Typography } from "@mui/material";

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 700,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
  };

const ForoEmpresarial = () => {
	const [activeTab, setActiveTab] = useState("publicaciones");

	const [infoGrupo, setInfoGrupo] = useState({});

	const { logotipo, color, nombre } = infoGrupo;

	const [infoGrupoModificada, setInfoGrupoModificada] = useState(false);

	const joyrideRef = useRef(null);

	const [tourActivo, setTourActivo] = useState(false);

	const [runTour, setRunTour] = useState(false);

	const editButtonRef = useRef(null);

	const [solicitarDatosEmpresa, setSolicitarDatosEmpresa] = useState(false);

	const [tourSteps, setTourSteps] = useState([
		{
			target: ".question-foro-empresarial",
			content:
				"Bienvenido al foro empresarial, dentro varás las publicaciones que tu asesor ha hecho para ti, lecciones personalizdas acorde a las necesidades de tu empresa y por ultimo, verás quienes conforman tu grupo empresarial, tanto compañeros estudiantes como a los asesores encargados del grupo.",
			disableBeacon: true,
			placement: "left-start",
		},
		{
			target: ".end-foro-empresarial",
			content: "Navega por la sección usando las pestañas que se presentan a continuación.",
			disableBeacon: true,
			placement: "left-start",
		},
	]);

	//STATE PARA LOADER
	const [showLoader, setShowLoader] = useState(true);

	const { contextValue, setContextValue } = useContext(UserContext);

	const { usuarioLogeado } = contextValue;

	let { rol, idCredencial, usuarioTitular, idGrupo, folioGrupo, cantidadEstudiantesGrupo, cantidadCertificacionesMos, nombreCompleto, apellidos, correo } = usuarioLogeado;

	if (idGrupo !== null && rol !== "administrador" && rol !== "asesor") {
		rol = "empleado";
	}

	if (rol === typesTiposUsuario.administrador || rol === typesTiposUsuario.capacitador) {
		idGrupo = 1;

		folioGrupo = "127";
	}

	const [tabs, setTabs] = useState([
		{
			eventKey: "publicaciones",
			title: "Publicaciones",
			getComponent: (props) => <Publicaciones {...props} infoGrupo={infoGrupo} rol={rol} idGrupo={idGrupo} />,
		},
		{
			eventKey: "lecciones",
			title: "Lecciones",
			getComponent: (props) => <Lecciones {...props} idGrupo={idGrupo} rol={rol} tab={activeTab} asesor={{
				nombreCompleto,
				apellidos,
				correo
			}} />,
		},
		{
			eventKey: "colaboradores",
			title: "Colaboradores",
			getComponent: (props) => <Colaboradores {...props} folioGrupo={folioGrupo} infoGrupo={infoGrupo} idGrupo={idGrupo} tab={activeTab} rol={rol} usuarioLogeado={usuarioLogeado} />,
		},
		{
			eventKey: "certificaciones",
			title: "Certificaciones MOS",
			getComponent: (props) => <CertificacionesMos {...props} />,
		},
		{
			eventKey: "reportes",
			title: "Reportes",
			getComponent: (props) => (
				<ReportesEmpresariales {...props} folioContrato={folioGrupo} idGrupo={idGrupo} cantidadEstudiantes={cantidadEstudiantesGrupo} cantidadCertificacionesMos={cantidadCertificacionesMos} />
			),
		},
	]);

	const defaultActiveTab = tabs[0].eventKey;

	const handleChangeTab = (tab) => {
		try {
			setActiveTab(tab);
		} catch (e) {
			console.log(e);
		}
	};

	const {
		size: { width },
	} = useWindowResize();

	const handleStartTour = () => {
		setRunTour(!runTour);
	};

	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	useEffect(() => {
		const obtenerDatosGrupo = async () => {
			try {
				const { data } = await getGrupoById(idGrupo);

				setInfoGrupo(data);

				const { nombre, nombreEmpresa } = data;

				if (nombre === "" || nombreEmpresa === "") {
					setSolicitarDatosEmpresa(true);
				}
				// console.log(data);
			} catch (error) {
				console.log(error);
			}
		};

		obtenerDatosGrupo();

		setShowLoader(false);
	}, []);

	useEffect(() => {
		if (infoGrupoModificada) {
			const obtenerDatosGrupo = async () => {
				try {
					const { data } = await getGrupoById(idGrupo);

					setInfoGrupo(data);

					const { nombre, nombreEmpresa } = data;

					if (nombre === "" || nombreEmpresa === "") {
						setSolicitarDatosEmpresa(true);
					}

					// console.log(data);
				} catch (error) {
					console.log(error);
				}
			};

			obtenerDatosGrupo();

			setInfoGrupoModificada(false);
		}
	}, [infoGrupoModificada]);

	useEffect(() => {
		if (solicitarDatosEmpresa) {
			editButtonRef.current.click();
		}
	}, [solicitarDatosEmpresa]);

	const breadcrumbItemsInitialState = [{ title: "Foro empresarial", onClick: () => {} }];

	return (
		<>
			{showLoader ? (
				<Loader height={"80vh"} setShowLoader={setShowLoader} />
			) : (
				<Animated animationIn="fadeIn" animationInDuration={2000} animationOut="fadeOut" isVisible={true}>
					<Container className="contenedor-foro">
						<Joyride
							locale={spanishOptionsJoyRide}
							ref={joyrideRef}
							steps={tourSteps}
							run={runTour}
							continuous={true}
							showSkipButton
							hideCloseButton
							disableOverlayClose={true}
							disableCloseOnEsc={false}
							disableScrolling={false}
							scrollToFirstStep={true}
							scrollOffset={100}
							callback={(data) => {
								if (data.status === "finished") {
									setRunTour(false);
								} else if (data.action === "skip") {
									setRunTour(false);
								}
							}}
							styles={{
								options: {
									primaryColor: "#ff9900",
									textColor: "#00152A",
									width: 900,
									zIndex: 1000,
								},
							}}
						/>

						{/* Modal para editar el grupo */}

							<Modal
								open={open}
								onClose={handleClose}
								aria-labelledby="modal-modal-title"
								aria-describedby="modal-modal-description"
							>
								<Box sx={style}>
									<EditarGrupo solicitarDatosEmpresa={solicitarDatosEmpresa} handleClose={handleClose} infoGrupo={infoGrupo} setInfoGrupo={setInfoGrupo} setInfoGrupoModificada={setInfoGrupoModificada} idGrupo={idGrupo} />
								</Box>
							</Modal>


						<Row
							className="justify-content-between d-flex align-items-center text-center p-3"
							style={{
								backgroundColor: `#${color}`,
								width: "100%",
								height: "auto",
								margin: "0",
								borderRadius: "0px 0px 20px 20px",
							}}>
							<Col className="col-3">
								<img className="logo-empresarial img-fluid" src={logotipo !== "" ? logotipo : require("@assets/images/logo_grupo_empresarial.png")} width="150" alt="Logo-empresarial" />
							</Col>

							{rol === "asesor" && (
								<Col className="col-3">
									<OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-top`}>Editar Grupo</Tooltip>}>
										<Button
											ref={editButtonRef}
											onClick={handleOpen}
											type="button"
											className="btn btn-dark text-center">
											<FontAwesomeIcon style={{margin: 0, padding: 0}} color="white" icon={faEdit} />
										</Button>
									</OverlayTrigger>
								</Col>
							)}
						</Row>

						<Row>
							<Col>
								<DynamicBreadcrumb arrayBreadcrumbs={breadcrumbItemsInitialState} />
							</Col>

							{width > 768 && (
								<Col className="col-auto question-foro-empresarial end-foro-empresarial d-flex align-items-center">
									<OverlayTrigger placement={"top"} overlay={<Tooltip>Conoce tu entorno</Tooltip>}>
										<span>
											<FontAwesomeIcon className="mx-5 pointer " style={{ fontSize: "2rem" }} onClick={handleStartTour} icon={faQuestion} />
										</span>
									</OverlayTrigger>
								</Col>
							)}
						</Row>

						<Row className="mt-5 mb-4">
							<Col>
								<TitleDashboard title={"Foro empresarial: " + nombre} />
							</Col>
						</Row>

						{/* //*TABS DE COMPONENTES */}
						<Row className="mt-5">
							<Col>
								<Tabs className="test" defaultActiveKey={defaultActiveTab} onSelect={handleChangeTab} activeKey={activeTab}>
									{tabs.map((tab, idx) => {
										const component = (
											<Tab key={idx} eventKey={tab.eventKey} title={tab.title}>
												{tab.getComponent({
													isTabActive: tab.eventKey === activeTab,
												})}
											</Tab>
										);

										if (rol !== typesTiposUsuario.empleado) {
											return component;
										}

										if (!["certificaciones", "reportes"].includes(tab.eventKey)) {
											return component;
										} else {
											return null;
										}
									})}
								</Tabs>
							</Col>
						</Row>
					</Container>
				</Animated>
			)}
		</>
	);
};

export default ForoEmpresarial;
