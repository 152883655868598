import React, { useContext, useRef, useState } from "react";
import { NotificacionExtendida } from "@components/general/Alertas/Alertas";
import { getNotificacionesUsuario, updateFechaUltimoAcceso, validateCredenciales } from "@helpers/httpRequests/httpRequestsUsuarios/httpRequestsUsuarios";
import UserContext from "@context/User/UserContext";
import dashboardRoutesPaths from "@helpers/dashboardRoutesPaths/dashboardRoutesPaths";
import getUnixTimestamp from "@helpers/dates/getUnixTimestamp/getUnixTimestamp";
import localStorageItemNames from "@helpers/localStorage/localStorageItemNames";
import typesTiposUsuario from "@helpers/types/typesTiposUsuario";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faSpinner } from "@fortawesome/free-solid-svg-icons";
import useCapsLockWatcher from "@customHooks/useCapsLockWatcher";
import CapsLockTooltip from "@components/general/CapsLockTooltip/CapsLockTooltip";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FloatingLabel, Image } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
export const IniciarSesion = ({ mobileBtnRegistro }) => {
	const history = useHistory();

	const inputContrasenia = useRef(null);

	const isCapsLockOn = useCapsLockWatcher(inputContrasenia);

	const [formValues, setFormValues] = useState({
		nombreUsuario: "",
		contrasenia: "",
		acceso: false,
	});

	const [showPassword, setShowPassword] = useState(false);

	const [showSpinner, setShowSpinner] = useState(false);

	const { nombreUsuario, contrasenia } = formValues;

	const { contextValue, setContextValue } = useContext(UserContext);

	/**
	 * Metodo encargado de validar credenciales y
	 * dar acceso al dashboard
	 * @param {*} e
	 */
	const handleCredentials = async (e) => {
		e.preventDefault();

		setShowSpinner(true);

		try {
			const { tipoUsuario, token, datosUsuario: arrayDatosUsuario } = await validateCredenciales({ nombreUsuario, contrasenia });

			const [datosUsuario] = arrayDatosUsuario;

			let pathRedirection = "";

			switch (tipoUsuario) {
				case typesTiposUsuario.estudiante:
					if (datosUsuario.segundoPerfilRegistrado) {
						pathRedirection = dashboardRoutesPaths.perfiles;
					} else {
						pathRedirection = dashboardRoutesPaths.dashboard;
					}
					break;

				case typesTiposUsuario.asesor:
				case typesTiposUsuario.empleado:
				case typesTiposUsuario.administrador:
				case typesTiposUsuario.vendedor:
				case typesTiposUsuario.capacitador:
				case typesTiposUsuario.invitado:
				case typesTiposUsuario.lecturaVeloz:
					pathRedirection = dashboardRoutesPaths.dashboard;

					break;

				default:
					pathRedirection = dashboardRoutesPaths.default;

					break;
			}

			if (!datosUsuario.intereses && tipoUsuario !== typesTiposUsuario.invitado) {
				pathRedirection = dashboardRoutesPaths.intereses;
			}

			// const ultimoAcceso = getUnixTimestamp();

			// try {
			// 	await updateFechaUltimoAcceso({
			// 		idPersona: datosUsuario.idPersona,
			// 		fecha: ultimoAcceso,
			// 	});
			// } catch (error) {
			// 	console.log(error);
			// }

			// fetch('https://api.ipify.org?format=json')
			// 	.then(response => response.json())
			// 	.then(data => {
			// 		const ip = data.ip;

			// 		fetch(`https://ipapi.co/${ip}/json/`)
			// 			.then(function (response) {
			// 				response.json().then(jsonData => {
			// 					console.log(jsonData);

			// 					setContextValue({
			// 						...contextValue,
			// 						usuarioLogeado: datosUsuario,
			// 						paisSesion: jsonData.country
			// 					});
			// 				});
			// 			})
			// 			.catch(function (error) {
			// 				console.log(error)
			// 			});
			// 	});

			setContextValue({
				...contextValue,
				usuarioLogeado: datosUsuario,
			});

			setShowSpinner(false);

			localStorage.setItem(localStorageItemNames.datosUsuario, JSON.stringify(datosUsuario));

			localStorage.setItem(localStorageItemNames.token, token);

			history.replace(pathRedirection);
		} catch (error) {
			console.log("error validando las credenciales: ", error);

			setShowSpinner(false);

			const { code, message } = error;

			if (code === 500) {
				let notificationMessage = "";

				switch (message) {
					case "wrong_password":
						notificationMessage = "La contraseña es incorrecta, verifica que los datos sean correctos.";
						break;

					case "user_not_found":
						notificationMessage = "Usuario no encontrado, verifica que los datos sean correctos";
						break;

					case "try_again":
						notificationMessage = "Ocurrió un error inesperado, vuelve a intentar. Si el error continúa, ponte en contacto con Soporte Técnico";
						break;
					case "use_alias":
						notificationMessage = "Algo sucedió al validar tu nombre de usuario, utiliza tu alias para iniciar sesión.\n Si el problema persiste contacta a Soporte.";
						break;
					default:
						break;
				}

				NotificacionExtendida("error", notificationMessage);
			}

			//Entra aqui cuando el estatus del usuario es 0
			if (code === 401) {
				const { data, message } = error;

				const { tipoUsuario, token, datosUsuario: arrayDatosUsuario } = data;

				const [datosUsuario] = arrayDatosUsuario;

				if (message === "Servicio ináctivo") {
					NotificationManager.warning("Servicio inactivo, renueva para disfrutar de todos los beneficios de tu plataforma Click+");

					setContextValue({
						...contextValue,
						usuarioLogeado: datosUsuario,
					});

					setShowSpinner(false);

					localStorage.setItem(localStorageItemNames.datosUsuario, JSON.stringify(datosUsuario));

					localStorage.setItem(localStorageItemNames.token, token);

					history.replace(dashboardRoutesPaths.dashboard);
				}
			}
		}
	};

	/**
	 * metodo encargado de recopilar informacion de los input y
	 * guardarlos en la variables local de estado formValues
	 * @param {*} event
	 */
	const handleInputChange = (event) => {
		try {
			event.preventDefault();

			const { name, value } = event.target;

			setFormValues({
				...formValues,
				[name]: value.trim(),
			});
		} catch (error) {
			console.log(error);
		}
	};

	const handleShowPassword = () => {
		setShowPassword(!showPassword);
	};

	return (
		<Row className={`text-center justify-content-center align-items-center h-100`}>
			<Col>
				<Container fluid>
					<Row>
						<Image src={require("@assets/images/click.png")} height={125} className="d-md-none" />

						<div className="avatar-inicio"></div>
					</Row>

					<Row>
						<div className="d-flex justify-content-center align-items-center">
							<Form onSubmit={handleCredentials} className="text-center">
								<h2 className="text-dark">Inicio de sesi&oacute;n</h2>

								<FloatingLabel label="Nombre de usuario" className="mb-3">
									<Form.Control
										className="input-4 input-field"
										required
										type="text"
										placeholder="Nombre de usuario"
										value={nombreUsuario}
										name="nombreUsuario"
										onChange={handleInputChange}
										autoComplete="off"
									/>
								</FloatingLabel>

								<Form.Group as={Col} className="align mt-3">
									<InputGroup className="mt-3">
										<InputGroup.Text onClick={handleShowPassword} className="pointer">
											<FontAwesomeIcon icon={faEye} />
										</InputGroup.Text>

										<FloatingLabel label="Contraseña">
											<Form.Control
												className="input-field"
												required
												name="contrasenia"
												value={contrasenia.trim()}
												type={showPassword ? "text" : "password"}
												placeholder="Confirma tu contraseña"
												onChange={handleInputChange}
												autoComplete="off"
												ref={inputContrasenia}
											/>
										</FloatingLabel>

										<CapsLockTooltip input={inputContrasenia} isCapsLockOn={isCapsLockOn} />
									</InputGroup>
								</Form.Group>

								<Form.Group as={Col} className="align mt-3">
									<div className="d-grid gap-2">
										<Button type="submit" className="btn-warning btn4" size="lg">
											Iniciar sesi&oacute;n {showSpinner && <FontAwesomeIcon icon={faSpinner} />}
										</Button>
									</div>
								</Form.Group>
							</Form>
						</div>
					</Row>

					<Row className="text-center justify-content-center align-items-center mt-3">
						<a style={{ textDecoration: "none" }} href={dashboardRoutesPaths.recuperacionCredenciales}>
							<p className="p text-primary">Olvid&eacute; mi contraseña</p>
						</a>
					</Row>

					<Row className="text-center justify-content-center align-items-center">
						<a style={{ textDecoration: "none" }} href={dashboardRoutesPaths.recuperacionNombreUsuario}>
							<p className="p text-primary">Olvid&eacute; mi nombre usuario</p>
						</a>
					</Row>
				</Container>
			</Col>
		</Row>
	);
};
