import React, { useContext, useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import PaquetesIncluidos from "./PaquetesIncluidos";
import TotalAPagar from "./TotalAPagar";
import AvisoProductoDigital from "./AvisoProductoDigital";
import FormCodigoDescuento from "./FormCodigoDescuento";
import UserContext from "@context/User/UserContext";

const Body = ({ precios, compraExterna }) => {
	const { contextValue, setContextValue } = useContext(UserContext);

	const [codigoAplicado, setCodigoAplicado] = useState(false);

	const [codigoDescuento, setCodigoDescuento] = useState("");

	const [preciosXCodigo, setPreciosXCodigo] = useState(precios?.descuentoNoAplicado);

	useEffect(() => {
		if (codigoAplicado && precios?.descuentoAplicado) {
			setContextValue({
				...contextValue,
				preciosDescuento: preciosXCodigo,
			});
		}
	}, [preciosXCodigo]);

	return (
		<>
			<Row className="detalle-compra-pricing">
				<PaquetesIncluidos codigoAplicado={codigoAplicado} />

				<TotalAPagar 
					compraExterna={compraExterna}
					codigoAplicado={codigoAplicado} 
					codigoDescuento={codigoDescuento} 
					descuentoAplicado={precios?.descuentoAplicado} 
					descuentoNoAplicado={precios?.descuentoNoAplicado} 
				/>
			</Row>
			
			<Row className="detalle-compra-pricing pb-4 pb-lg-5">
				<AvisoProductoDigital />

				{/* Se envian los precios con descuento para mostrarlos si tienen el codigo valido */}
				<FormCodigoDescuento 
					compraExterna={compraExterna}
					precios={precios?.descuentoAplicado} 
					states={
						{
							stateCodigoAplicado: {
								codigoAplicado,
								setCodigoAplicado,
							}, 
							stateCodigoDescuento: {
								codigoDescuento,
								setCodigoDescuento
							}, 
							statePreciosXCodigo	: {
								preciosXCodigo,
								setPreciosXCodigo,
							}
						}
					}
				/>
			</Row>
		</>
	);
};

export default Body;
