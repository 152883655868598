import TitleDashboard from "@components/general/Titles/TitleDashboard";
import DetalleRenovacion from "@components/usuario/RenovacionLicencia/DetalleRenovacion";
import UserContext from "@context/User/UserContext";
import numberCommaSeparator from "@helpers/formateoPrecios/numberCommaSeparator";
import { enviarComprobanteRenovacion } from "@helpers/httpRequests/httpRequestsMailers/httpRequestsMailers";
import { retrievePaymentIntent } from "@helpers/httpRequests/httpRequestsPagos/httpRequestsPagos";
import { getDatosUsuario, renovarLicencia } from "@helpers/httpRequests/httpRequestsUsuarios/httpRequestsUsuarios";
import typesTiposUsuario from "@helpers/types/typesTiposUsuario";
import { Alert, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";
import { NotificationManager } from "react-notifications";

const enviarComprobante = (datosComprobante, formValues, datosCompra, paymentMethod, paymentIntent) => {
	return new Promise(async (resolve, reject) => {
		try {

			const { folioContrato, nombre, apellidoPaterno, apellidoMaterno, correo } = formValues;

			const { metodoPago, precios } = datosCompra;

			const {
				card: { last4 },
			} = paymentMethod;

			const {
				id: paymentIntentId,
				amount: montoCobro,
				payment_method_options: { card },
			} = paymentIntent;

			const titular = `${nombre.trim()} ${apellidoPaterno.trim()} ${apellidoMaterno.trim()}`;

			const replacements = {
				folioContrato,
				titular,
				numeroTarjeta: `Tarjeta con terminación ${last4}`,
				metodoPago: metodoPago === "contado" ? "De contado" : metodoPago === "credito" ? "Crédito Directo" : `${card.installments.plan.count} Meses sin intereses`,
				description: `Renovación de Licencia Click+ Premium - Folio ${folioContrato}`,
				paymentIntentId,
				correo,
				montoCobro: numberCommaSeparator(montoCobro / 100),
				costoBase:
					metodoPago === "contado" ? numberCommaSeparator(precios.contado) : metodoPago === "credito" ? numberCommaSeparator(precios.credito.precioTotal) : numberCommaSeparator(precios.msi.monto),
				...(metodoPago === "credito"
					? {
							cantidadRestante: numberCommaSeparator(precios.credito.precioTotal - montoCobro / 100),
					  }
					: metodoPago.includes("msi")
					? {
							msi: card.installments.plan.count,
					  }
					: {}),
			};

			await enviarComprobanteRenovacion(replacements);

			resolve();
		} catch (error) {
			console.log("Error al enviar el comprobante de compra: ", error);

			reject(error);
		}
	});
};

const RenovacionLicencia = () => {
	const { contextValue, setContextValue } = useContext(UserContext);

	const { renovacion, usuarioLogeado } = contextValue;

	let { folioContrato, rol, idPersona, idCredencial, licenciaRenovacion, folioRenovacion } = usuarioLogeado;

	if (rol === typesTiposUsuario.administrador) {
		folioContrato = "753";
	}


	const [licenciaRenovada, setLicenciaRenovada] = useState(false);

	const getCostoTotal = () => {
		try {
			const { precios } = renovacion;

			let costoTotal = 0;

			switch (renovacion.metodoPago) {
				case "contado":
				case "msi":
					costoTotal = precios[renovacion.metodoPago].monto;

					break;

				case "credito":
					costoTotal = precios.credito.precioTotal;
					break;

				default:
					console.log("metodo de pago desconocido", renovacion.metodoPago);
					break;
			}

			return costoTotal;
		} catch (error) {
			console.log("error al obtener el costo total: ", error);
		}
	};

	const getAmortizacionesTotales = () => {
		try {
			let amortizacionesTotales = 0;

			if (renovacion.metodoPago === "contado" || renovacion.metodoPago === "msi") {
				amortizacionesTotales = 1;
			} else {
				const { precios } = renovacion;

				amortizacionesTotales = precios.credito.totalPagosQuincenales;
			}

			return amortizacionesTotales;
		} catch (error) {
			console.log("error al obtener las amortizaciones totales: ", error);
		}
	};

	const getMontoQuincenal = () => {
		try {
			let montoQuincenal = null;

			if (renovacion.metodoPago !== "contado") {
				const { precios } = renovacion;

				montoQuincenal = precios.credito.pagoQuincenal;
			}

			return montoQuincenal;
		} catch (error) {
			console.log("error al obtener el monto quincenal: ", error);
		}
	};

	const procesarRenovacion = async () => {
		try {
			const { stripeSession } = renovacion;

			const { amount_total, payment_intent: paymentIntentId } = stripeSession;

			const { paymentIntent, paymentMethod } = await retrievePaymentIntent(paymentIntentId);

			const { id: folioPago, amount } = paymentIntent;

			const {
				card: { last4, funding },
			} = paymentMethod;

			const datosRenovacion = {
				folioContrato,
				pagoDeContado: renovacion.metodoPago === "contado",
				costoTotal: getCostoTotal(),
				amortizacionesTotales: getAmortizacionesTotales(),
				montoPagado: amount_total / 100,
				montoQuincenal: getMontoQuincenal(),
				metodoPago: renovacion.metodoPago,
				tipoTarjeta: funding,
				referenciaPago: paymentIntentId,
			};

			await renovarLicencia({
				folioContrato: usuarioLogeado.folioContrato,
				datosRenovacion,
				idPersona,
				idCredencial,
			});

			const { data: datosUsuario } = await getDatosUsuario({
				tipoUsuario: usuarioLogeado.rol,
				idCredencial: usuarioLogeado.idCredencial,
				usuarioTitular: usuarioLogeado.usuarioTitular,
			});

			const [datosUsuarioActualizados] = datosUsuario;

			setContextValue({
				...contextValue,
				usuarioLogeado: datosUsuarioActualizados,
				renovacion: undefined,
			});

			await enviarComprobante(
				{
					titular: `${usuarioLogeado.nombreCompleto} ${usuarioLogeado.apellidos}`,
					paymentIntentId: folioPago,
					folioContrato,
					montoCobro: amount / 100,
					numeroTarjeta: `Tarjeta con terminación ${last4}`,
					description: `Renovación de Licencia Click+ - Folio ${folioContrato}`,
					correo: usuarioLogeado.correo,
				},
				{ 
					folioContrato, 
					nombre: usuarioLogeado.nombreCompleto, 
					apellidoPaterno: usuarioLogeado.apellidos.split(" ")[0], 
					apellidoMaterno: usuarioLogeado.apellidos.split(" ")[0], 
					correo: usuarioLogeado.correo 
				},
				{ metodoPago: renovacion.metodoPago, precios: renovacion.precios },
				paymentMethod,
				paymentIntent
			);

			NotificationManager.success("¡Renovación efectuada con éxito!");

			// setTimeout(() => window.location.reload(), 1500);
		} catch (error) {
			console.log("Error al renovar la licencia: ", error);
		}
	};

	useEffect(() => {
		if (renovacion) {
			if (renovacion.pagoFinalizado) {
				procesarRenovacion();
			}
		}

		if (folioRenovacion !== null) {
			setLicenciaRenovada(true);
		}
	}, []);

	return (
		<Container fluid className="py-3">
			{licenciaRenovada && licenciaRenovacion ? (
				<Card body className="mb-3">
					<TitleDashboard title="Tu licencia se encuentra en un plan de renovación, tienes acceso de por vida a partir de ahora" />

					<br />

					<Typography variant="body1" gutterBottom>
						Renovaciones efectuadas: {folioRenovacion}
					</Typography>

					<br />

					<Typography variant="overline" gutterBottom>
						Restricciones
					</Typography>

					<br />

					<Typography gutterBottom variant="caption">
						<span className="text-danger">*</span> Dejarás de recibir actualizaciones en todo nuestro contenido después de 18 meses
					</Typography>

					<br />

					<Typography gutterBottom variant="caption">
						<span className="text-danger">*</span> Dejarás de recibir asesorías personalizadas después de 18 meses
					</Typography>

					<br />

					<Typography gutterBottom variant="caption">
						<span className="text-danger">*</span> Perderás acceso a tu Certificación MOS después de 18 meses. ¡Aprovéchala!
					</Typography>

					<br />

					<Typography gutterBottom variant="caption">
						<span className="text-danger">*</span> No podrás cambiar la información de ninguno de tus usuarios adquiridos.
					</Typography>

					<br />
					
					<Alert className="mt-3" severity="info">Si dejaste de recibir actualizaciones, podrás volver a adquirir una renovación para obtener las nuevas actualizaciones de la plataforma.</Alert>
				</Card>
			) : null}

			<TitleDashboard title="Renovar Licencia" />

			<DetalleRenovacion />
		</Container>
	);
};

export default RenovacionLicencia;
